import { UserAgentApplication } from "msal";
import { AuthProvider, AuthProviderCallback, Client } from "@microsoft/microsoft-graph-client";
import config from "../env-config";
import moment from 'moment';
import { Supervisor } from "../scenes/PhoneSheetScene/services/models";
import { ContactInformation } from "../components/createContact";

let msalClient: UserAgentApplication;
let client: Client;

const getMSALClient = () => {
    if (!msalClient) {
        msalClient = new UserAgentApplication({
            auth: {
                clientId: config.appId,
                redirectUri: `${window.location.origin}/login`,
                authority: `https://login.microsoftonline.com/${config.tenantId}`
            },
            cache: {
                cacheLocation: "localStorage",
                storeAuthStateInCookie: true,
            },

        });
    }

    return msalClient;
};

const getAccessToken = async () => {
    if (!msalClient) {
        getMSALClient()
    }
    try {
        const { accessToken } = await msalClient.acquireTokenSilent({
            scopes: config.scopes
        });
        return accessToken;
    } catch (error) {
        console.error(`💥 GraphService:getAccessToken: error getting token ${error}`);

    }
}

const getIdToken = async () => {
    if (!msalClient) {
        getMSALClient()
    }
    try {
        const { idToken } = await msalClient.acquireTokenSilent({
            scopes: config.scopes
        });
        return idToken;
    } catch (error) {
        console.error(`💥 GraphService:getAccessToken: error getting token ${error}`);

    }
}

const authProvider: AuthProvider = async (callback: AuthProviderCallback) => {
    try {
        const accessToken = await getAccessToken();
        accessToken && callback(null, accessToken);
    } catch (error) {
        callback(error, null);

        console.log(error)
        console.error("💥 GraphService:authProvider: uh-oh, AuthProvider failed to get access login.");
    }
}

const request = async (endpoint: string) => {
    if (msalClient) {
        if (!client) {
            client = Client.init({ authProvider });
        }

        return await client.api(endpoint).get();
    } else {
        console.error("💥 GraphService:request: msalClient undefined, re-run getMSALClient first to authentication");
    }
};

const mapEmailsArray = (emails: string[]) => emails.map((email) => {
    return {
        emailAddress: {
            address: email
        }
    }
})


const sendEmail = async (endpoint: string, content: string, supervisor: Supervisor, sendToEmails: string[], ccEmails: string[]) => {
    const now = moment().format("MM/D/yy h:mm A");

    const sendMail = {
        message: {
            subject: `${supervisor.name}'s Phone Sheet ${now}`,
            body: {
                contentType: "HTML",
                content: content
            },
            toRecipients: mapEmailsArray(sendToEmails),
            ccRecipients: mapEmailsArray(ccEmails),
        },
    };
    if (msalClient) {
        if (!client) {
            client = Client.init({ authProvider });
        }
        return await client.api(endpoint).post(sendMail);
    } else {
        console.error("💥 GraphService:postRequest: msalClient undefined, re-run getMSALClient first to authentication");
    }
};

export interface OutlookSearchUser {
    displayName: string;
    id: string;
    userPrincipalName: string;
};

const searchUsers = async (query: string): Promise<OutlookSearchUser[]> => {
    if (!client) {
        client = Client.init({ authProvider });
    }

    try {
        const baseQuery = `/users/`;
        const filter = `$filter=(startsWith(displayName,'${query}')) or ((startsWith(userPrincipalName, '${query}')))`;
        const select = `$select=id,userPrincipalName,displayName`;

        const { value = [] } = await client.api(`${baseQuery}?${filter}&${select}`).get();
        return value as OutlookSearchUser[];
    } catch (error) {
        console.error("💥 GraphService:searchUsers: uh-oh, AuthProvider failed to get access login.", error.message);
        new Error(error);
    }

    return [];
};

const getUserContacts = async (userId: string): Promise<any[]> => {
    if (!client) {
        client = Client.init({ authProvider });
    }

    try {
        const query = `/users/${userId}/contacts`;
        const { value = [] } = await client.api(query).get();
        return value;
    } catch (error) {
        console.error("💥 GraphService:getUserContacts: uh-oh, AuthProvider failed to get access login.", error.message);
        throw error;
    }
};

const createContact = async (userId: string, contact: ContactInformation): Promise<any> => {
    if (!client) {
        client = Client.init({ authProvider });
    }

    try {
        const query = `users/${userId}/contacts`;
        const response = await client.api(query).post(contact);
        return response;
    } catch (error) {
        console.error("💥 GraphService:createContact: uh-oh, failed to create contact", error.message);
        throw error;
    }
};

const patchContact = async (userId: string, contactId: string, contact: ContactInformation): Promise<any> => {
    if (!client) {
        client = Client.init({ authProvider });
    }

    try {
        const query = `users/${userId}/contacts/${contactId}`;
        const response = await client.api(query).patch(contact);
        return response;
    } catch (error) {
        console.error("💥 GraphService:createContact: uh-oh, failed to create contact", error.message);
        throw error;
    }
};

const services = {
    getAccessToken,
    getMSALClient,
    getIdToken,
    request,
    sendEmail,
    searchUsers,
    getUserContacts,
    createContact,
    patchContact,
};

export default services;