import React, { useEffect } from 'react';
import { Form, Input, Button, Divider } from 'antd';
import { CountryPhoneInput } from '../common';

import { CountryPhoneInputValue, PhoneType } from '../common/country-phone-input';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import parsePhoneNumber from 'libphonenumber-js'


export interface ContactInformation {
    givenName: string;
    surname: string;
    emailAddresses?: Array<{ address: string, name: string }>;
    businessPhones?: string[];
    homePhones?: string[];
    mobilePhone?: string;
};

export interface FormContactPhoneType {
    type: 'business' | 'mobile' | 'home',
    value: string;
}

export interface CreateContactProps {
    onSubmit: (p: ContactInformation) => void;
    isLoading?: boolean;
    existingContact?: any;
    isEdit?: boolean;
};

interface ContactInformationForm {
    contact: {
        givenName: string;
        surname: string;
        email?: string,
        home?: CountryPhoneInputValue,
        mobile?: CountryPhoneInputValue,
        business?: CountryPhoneInputValue,
    }
    contacts: Array<CountryPhoneInputValue>
}

const CreateContact = ({ onSubmit, isLoading = false, existingContact, isEdit = false }: CreateContactProps) => {
    const [form] = Form.useForm();

    const layout = {
        labelCol: {
            span: 6,
        },
        wrapperCol: {
            span: 16,
        },
    };

    const _generateContactForm = (type: PhoneType, number: string): CountryPhoneInputValue => {
        const parsedNumber = parsePhoneNumber(number, 'US');
        const code = Number(parsedNumber?.countryCallingCode || 1);
        const intlNumber = parsedNumber?.formatInternational();
        const phone = parsedNumber?.formatNational();
        const isValidNumber = true;

        return {
            type,
            code,
            intlNumber,
            phone,
            isValidNumber,
        }
    }
    useEffect(() => {
        if (isEdit) {
            const { businessPhones = [], homePhones = [], mobilePhone } = existingContact;

            const _business = businessPhones.map((b: string) => _generateContactForm({
                label: 'Business',
                value: 'business',
            }, b));

            const _homePhones = homePhones.map((b: string) => _generateContactForm({
                label: 'Home',
                value: 'home',
            }, b));

            const _mobile = (mobilePhone && [_generateContactForm({
                label: 'Mobile',
                value: 'mobile',
            }, mobilePhone)]) || [];


            form.setFieldsValue({
                contacts: [..._business, ..._homePhones, ..._mobile]
            });
        }
        else {
            form.resetFields();
        }
    }, [existingContact, form, isEdit]);

    const onFinish = (value: ContactInformationForm): void => {
        const { contacts = [] } = value || {};
        const phones = contacts.reduce((acc: any, { phone, type, intlNumber, code }: any) => {
            const number = (intlNumber.length && code !== 1) ? intlNumber : phone;

            if (type.value === 'business') {
                acc.businessPhones.push(number);
                return acc;
            }

            if (type.value === 'home') {
                acc.homePhones.push(number);
                return acc;
            }

            if (type.value === 'mobile') {
                acc.mobilePhone = number;
            }

            return acc;
        }, {
            businessPhones: [], mobilePhone: undefined, homePhones: [],
        });

        const { businessPhones, mobilePhone, homePhones } = phones;

        if (isEdit) {
            onSubmit({
                homePhones,
                businessPhones,
                mobilePhone,
            } as any);

        } else {
            const { givenName, surname, email } = value.contact;


            let emailAddresses;
            if (email) {
                emailAddresses = [{ address: email, name: `${givenName} ${surname}` }];
            }

            onSubmit({
                givenName,
                surname,
                emailAddresses,
                homePhones,
                businessPhones,
                mobilePhone,
            });
            form.resetFields();
        }
    };

    const validateMessages = {
        required: "Required",
    };

    return (
        <React.Fragment>
            <h1> {(!isEdit && 'Create a New Contact') || 'Edit Contact'} </h1>
            <Divider />

            <Form {...layout} form={form} onFinish={onFinish} validateMessages={validateMessages} >
                {!isEdit &&
                    <React.Fragment>
                        <h3> Contact Information </h3>
                        <Form.Item
                            name={['contact', 'givenName']}
                            label="First Name"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            name={['contact', 'surname']}
                            label="Last Name"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            name={['contact', 'email']}
                            label="Email"
                            rules={[
                                {
                                    type: 'email',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </React.Fragment>
                }
                <h3> Phone number </h3>
                <Form.List name="contacts">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(field => (
                                <div style={{ display: 'flex' }}>
                                    <Form.Item
                                        {...field}
                                        style={{ flexGrow: 1 }}
                                        wrapperCol={{}}
                                    >
                                        <CountryPhoneInput />

                                    </Form.Item>
                                    <MinusCircleOutlined style={{ marginTop: '10px', marginLeft: '2px' }} onClick={() => remove(field.name)} />
                                </div>
                            ))}
                            <Form.Item style={{ marginLeft: 'auto', marginRight: 'auto' }} wrapperCol={{}}>
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    Add Phone Number
                            </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
                <Form.Item noStyle style={{ width: '100%', padding: '10px' }}>
                    <div style={{ display: 'flex', flexDirection: 'row-reverse' }} >
                        <Button loading={isLoading} type="primary" htmlType="submit">
                            Submit
          </Button></div>
                </Form.Item>
            </Form>
        </React.Fragment >
    );
};

export default CreateContact;