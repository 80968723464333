import { GetProfileActions, GetProfileInfoActionTypes as Types, Supervisor, User } from "./models";
import { FormDataSource, PhoneType } from "../../../models/index";

export interface ProfileState {
    callsToProcess: FormDataSource[],
    preferences?: Preferences,
    userId?: string,
    error?: Error,
    loading: boolean;
    selectedSupervisor?: Supervisor;
    supervisors: Supervisor[];
    user?: User;
    isFirstTime: boolean;
}
export interface Preferences {
    contactColorTypes: {
        [PhoneType.LEFT_WORD]?: string;
        [PhoneType.TO_CALL]?: string;
        [PhoneType.OWE_CLIENT]?: string;
        [PhoneType.OWE_INTERNAL]?: string;
    }
}

export const ProfileStateInitialState: ProfileState = {
    loading: false,
    callsToProcess: [],
    isFirstTime: true,
    supervisors: []
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state: ProfileState = ProfileStateInitialState, action: GetProfileActions): ProfileState {
    switch (action.type) {
        case Types.GET_PROFILE_INFO: {
            return {
                ...state,
                loading: true,
            }
        }
        case Types.GET_PROFILE_INFO_SUCCESS: {
            return {
                ...state,
                loading: false,
            }
        }
        case Types.GET_PROFILE_INFO_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.error,
            }
        }
        case Types.SET_SUPERVISOR: {
            return {
                ...state,
                selectedSupervisor: action.supervisor,
            }
        }
        case Types.SET_ALL_SUPERVISORS: {
            return {
                ...state,
                supervisors: action.supervisors,
                selectedSupervisor: action.supervisors[0],
            }
        }
        case Types.SET_USER: {
            return {
                ...state,
                user: action.user,
                isFirstTime: action?.user?.isFirstTime,
            }
        }
        case Types.SET_FIRST_FLAG: {
            return {
                ...state,
                isFirstTime: action.flag
            }
        }
        default:
            return state;
    }
}