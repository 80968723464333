import React, { Dispatch, SetStateAction } from 'react';
import { Select } from 'antd';
import AutocompleteHook from './setupFlow/autocomplete.hook';

interface EmailOptionSelectProps {
    defaultEmail?: string;
    setEmails: Dispatch<SetStateAction<string[]>>
}


export const EmailOptionSelect = ({ defaultEmail, setEmails }: EmailOptionSelectProps) => {
    const { supervisors: users, setQuery } = AutocompleteHook();

    const { Option } = Select;
    const handleChange = (x: string[]) => {
        setEmails(x)
    }

    return (
        <Select
            mode="multiple"
            allowClear
            style={{ width: '90%' }}
            notFoundContent={null}
            defaultValue={defaultEmail ? [defaultEmail] : undefined}
            placeholder="Please enter Email"
            onChange={handleChange}
            onSearch={setQuery}
        >
            {users.map((user) => <Option value={user.email} key={user.email} >{user.email}</Option>)}
        </Select>
    )
};