import { gql } from "@apollo/client";


const GET_USER = gql`
  query User {
    User {
      userId
      isFirstTime
      supervisors {
        id
        name 
        email
      }
      contactsToProcess {
        uniqueId
        currentSessionTimestamps
        name
        phoneNumber
        type
        contactId
        comment
        callLog
        completed
      }
      preferences {
        contactColorTypes {
          toCall
          leftWord
          oweInternal
          oweClient
          order
        }
      }
    }
  }
`;

const UPATE_USER_PREFERENCES = gql`
  mutation UpdateUserPreferences($preferences: UserPreferencesInputTypeDef) {
    UpdateUserPreferences(preferences: $preferences)
  }
`;

const ADD_CALL = gql`
  mutation CreateContactToProcess(
    $contactToProcess: ContactsToProcessInputTypeDef
  ) {
    CreateContactToProcess(contactToProcess: $contactToProcess) {
      name
      uniqueId
      contactId
      type
      comment
      callLog
      currentSessionTimestamps
      phoneNumber {
        number
        type
    }
    }
  }
`;

const UPDATE_CALL = gql`
  mutation UpdateContactToProcess(
    $contactToProcess: ContactsToProcessInputTypeDef
  ) {
    UpdateContactToProcess(contactToProcess: $contactToProcess) {
      uniqueId
      type
    }
  }
`;

const UPDATE_USER = gql`
  mutation UpdateUser($isFirstFlag: Boolean!) {
    UpdateUser(isFirstFlag: $isFirstFlag) 
  }
`;

const exportsList = {
  GET_USER,
  UPATE_USER_PREFERENCES,
  ADD_CALL,
  UPDATE_CALL,
  UPDATE_USER,
};

export default exportsList;
