import { gql } from "@apollo/client";

const GET_CONTACT_BY_ID = gql`
query Outlook ($contactId: String!, $supervisorId: String!) {
    Outlook(contactId: $contactId, supervisorId: $supervisorId) {
        ContactById {
            mobilePhone
            businessPhones
            homePhones
        }
    }
}
`;

const exports = {
    GET_CONTACT_BY_ID,
};

export default exports;
