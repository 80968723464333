/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import { useDebounce } from "use-debounce/lib";
import GraphService, { OutlookSearchUser } from "../../utils/GraphService";
import { Supervisors } from './index';

export const mapOutlookUser = (user: OutlookSearchUser) => ({
    id: user.id,
    name: user.displayName,
    email: user.userPrincipalName,
});

const AutocompleteHook = () => {
    const [query, setQuery] = useState<string>('');
    const [isSearching, setIsSearching] = useState<boolean>(false);
    const [supervisors, setSupervisors] = useState<Supervisors[]>([]);
    const [debounceQuery] = useDebounce(query, 750);

    const fetchUsers = useCallback(async () => {
        setIsSearching(true);
        const supervisors = await GraphService.searchUsers(debounceQuery);
        const mappedOptions = supervisors.map(mapOutlookUser);
        setSupervisors(mappedOptions);
        setIsSearching(false);
    }, [debounceQuery]);

    useEffect(() => {
        if (!debounceQuery && debounceQuery.length === 0) { return; }
        if (isSearching) { return; }
        fetchUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debounceQuery]);

    return {
        query,
        supervisors,
        setQuery,
    }
};

export default AutocompleteHook;