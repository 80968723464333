import { combineReducers } from "redux";
import LoginSceneReducer from './scenes/LoginScene/services/reducer';
import ColorSelectionSceneReducer from './scenes/ColorSelectionScene/services/reducer';
import PhoneSheetSceneReducer from './scenes/PhoneSheetScene/services/reducer';


export const rootReducer = combineReducers({
    LoginSceneReducer,
    ColorSelectionSceneReducer,
    PhoneSheetSceneReducer
});

export type RootState = ReturnType<typeof rootReducer>

