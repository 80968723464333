import React from "react";
import { Menu, Dropdown, Button } from "antd";
import { UserOutlined, DownOutlined } from "@ant-design/icons";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "../rootReducer";
import { selectSupervisor } from "../scenes/PhoneSheetScene/services/actions";
import { DisplayWrapper } from "./DisplayWrapper";

export const MenuSelect = () => {
  const { supervisors, selectedSupervisor } = useSelector(
    (state: RootState) => state.PhoneSheetSceneReducer,
    shallowEqual
  );
  const dispatch = useDispatch();

  const menu = (
    <Menu>
      {supervisors?.map((boss) => (
        <Menu.Item onClick={(item) => handleOnClick(item)} icon={<UserOutlined />} key={boss.id}>
          {boss.name}
        </Menu.Item>
      ))}
    </Menu>
  );
  const handleOnClick = ({ key }: any) => {
    if (supervisors) {
      const index = supervisors?.findIndex((boss) => boss.id === key);
      const newSuper = supervisors[index];
      dispatch(selectSupervisor(newSuper));
    }
  };
  return (
    <DisplayWrapper display={!!supervisors?.length}>
      <Dropdown overlay={menu} trigger={["click"]}>
        <Button>
          {selectedSupervisor?.name} <DownOutlined />
        </Button>
      </Dropdown>
    </DisplayWrapper>
  );
};
