import { PhoneType } from '../../../models';
import { ColorSelectionSceneActionTypes, SetColor, SetAllColor } from './models';

export const setTypeColor = (phoneType: PhoneType, color: string): SetColor => ({
    type: ColorSelectionSceneActionTypes.SET_COLOR,
    phoneType,
    color,
})

export const setAllColor = (data: any): SetAllColor => ({
    type: ColorSelectionSceneActionTypes.SET_ALL_COLOR,
    data
})

export const setColorOrder = (data: string[]) => ({
    type: ColorSelectionSceneActionTypes.SET_COLOR_ORDER,
    data
})

