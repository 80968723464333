/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { Empty, AutoComplete, List, Button, Input, Card } from 'antd';
import { createUseStyles } from "react-jss";
import AutocompleteHook from './autocomplete.hook';
import { CheckCircleTwoTone, CloseCircleTwoTone, LoadingOutlined } from '@ant-design/icons';
import GraphService from '../../utils/GraphService';

export interface SetupFlowProps {
    onSubmit: (supervisors: Supervisors[]) => void,
    supervisors: SupervisorsListState[];
    modalFlag?: boolean;
};

export interface Supervisors {
    id: string;
    name: string;
    email: string;
}

export enum ValidationStatus {
    VALIDATING,
    VALIDATED,
    INVALID,
}

type SupervisorsListState = Supervisors & { status: ValidationStatus };

const styles = createUseStyles({
    container: {
        padding: '25px',
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        placeContent: 'space-evenly',
        alignItems: 'center',
    },
    typeaheadContainer: {
        padding: "60px 0"
    },
    footer: {
        display: "flex",
        flexDirection: "row-reverse",
    },
    modalCard: {
        border: 'none',
        width: '95%',
    },
    card: {
        width: '65%',
    },
    listItemContainer: {

    },
    validationStatus: {}
});

const VALIDATION_CACHE = new Map();

const SetupFlow = ({ supervisors, onSubmit, modalFlag }: SetupFlowProps) => {
    const validationMap: Map<string, Promise<any>> = new Map();

    const classes = styles();
    const [isValidating, setIsValidating] = useState<boolean>(false);
    const [isEligible, setIsEligible] = useState<boolean>(false);
    const [supervisorsList, setSupervisorsList] = useState<SupervisorsListState[]>(supervisors);
    const [options, setOptions] = useState<any[]>([]);
    const { query, supervisors: SearchSupervisors, setQuery } = AutocompleteHook();

    //#region Callbacks
    const mapOptions = (supervisors: Supervisors[] = []): any[] => {
        return supervisors.map(supervisor => ({
            key: supervisor.id,
            value: supervisor.name,
            id: `${supervisor.id}:${supervisor.email}`,
            data: { ...supervisor },
            label: `${supervisor.name} - ${supervisor.email}`,
        }));
    };

    const updateSupervisorStatus = (id: string, status: ValidationStatus) => {
        const newSupervisors = [...supervisorsList];
        newSupervisors.forEach(supervisor => {
            if (supervisor.id === id) {
                supervisor.status = status;
            }
        });

        setSupervisorsList(newSupervisors);
    };

    const validateUser = (id: string): Promise<any> => {
        const handleSuccess = () => {
            updateSupervisorStatus(id, ValidationStatus.VALIDATED);
            validationMap.delete(id);
            setIsValidating(validationMap.size !== 0);
            VALIDATION_CACHE.set(id, ValidationStatus.VALIDATED);
        };

        const handleError = () => {
            updateSupervisorStatus(id, ValidationStatus.INVALID);
            validationMap.delete(id);
            setIsValidating(validationMap.size !== 0);
            VALIDATION_CACHE.set(id, ValidationStatus.INVALID);
        };

        return GraphService.getUserContacts(id).then(handleSuccess).catch(handleError);
    };

    const handleTypeaheadSelect = (value: string, option: any) => {
        const { id } = option.data;
        const isIncluded = supervisorsList.find((supervisor) => supervisor.id === id);

        if (isIncluded) return;

        const status = VALIDATION_CACHE.get(id) || ValidationStatus.VALIDATING;
        const newSupervisors = [...supervisorsList];
        newSupervisors.push({ ...option.data, status });
        setSupervisorsList(newSupervisors);

        setQuery('');
        setOptions([]);
    };

    const handleSubmit = () => {
        const listToSave = supervisorsList.filter(s => s.status === ValidationStatus.VALIDATED);
        const formattedList = listToSave.map(l => ({
            id: l.id,
            email: l.email,
            name: l.name,
        }))
        onSubmit(formattedList);
    };
    //#endregion

    //#region Hooks
    useEffect(() => {
        setOptions(mapOptions(supervisors));
        supervisors.forEach((item) => VALIDATION_CACHE.set(item.id, ValidationStatus.VALIDATED));
    }, []);

    useEffect(() => {
        setOptions(mapOptions(SearchSupervisors));
    }, [SearchSupervisors]);

    useEffect(() => {
        if (!isValidating) {
            const eligible = supervisorsList.find(supervisor => supervisor.status === ValidationStatus.VALIDATED);

            setIsEligible(!!eligible);
        }
    }, [isValidating, supervisorsList]);

    useEffect(() => {
        // Avoid dups.
        supervisorsList.forEach(({ id }) => {
            if (!VALIDATION_CACHE.has(id) && !validationMap.has(id)) {
                validationMap.set(id, validateUser(id));
            }
        });

    }, [supervisorsList]);
    //#endregion

    const handleRemove = (id: string) => {
        const newList = [...supervisorsList];
        const index = newList.findIndex((item) => item.id === id);

        newList.splice(index, 1);
        setSupervisorsList(newList);
    }


    //#region Templates
    const SupervisorList = useCallback(() => (
        <List
            itemLayout="horizontal"
            dataSource={supervisorsList}
            rowKey={(item: SupervisorsListState) => item.id}
            renderItem={(item: SupervisorsListState) => (
                <List.Item actions={[<Button type='link' onClick={() => handleRemove(item.id)}>Remove</Button>]}>
                    <List.Item.Meta
                        title={item.name}
                        description={item.email}
                        avatar={
                            <div style={{ position: 'relative', margin: 'auto 20px auto auto' }}>
                                {item.status === ValidationStatus.VALIDATED && <CheckCircleTwoTone twoToneColor="#52c41a" />}
                                {item.status === ValidationStatus.INVALID && <CloseCircleTwoTone twoToneColor="#FF0000" />}
                                {item.status === ValidationStatus.VALIDATING && <LoadingOutlined twoToneColor="#52c41a" />}
                            </div>
                        }
                    />
                </List.Item>
            )}
        />
    ), [supervisorsList])

    const EmptyTemplate = () => (
        <Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{ height: 100 }}
            description={<h3>
                It looks like you are not managing any contacts. Add a Range Media user you'd like to manage. </h3>}
        >
        </Empty>
    );


    const AutocompleteTypeahead = () => (
        <div className={classes.typeaheadContainer}>
            <AutoComplete
                autoFocus
                defaultOpen
                style={{ width: '100%' }}
                options={options}
                value={query}
                onSearch={setQuery}
                onSelect={handleTypeaheadSelect}
            >
                <Input.Search size="large" placeholder="Search for user" enterButton />
            </AutoComplete>
        </div>
    )

    //#endregion
    return (
        <React.Fragment>
            <div className={classes.container}>
                <Card title="Add Contacts to Manage" className={modalFlag ? classes.modalCard : classes.card}>
                    {supervisorsList.length ? <SupervisorList /> : <EmptyTemplate />}

                    <AutocompleteTypeahead />

                    <div className={classes.footer}>
                        <Button
                            disabled={!isEligible}
                            type="primary" onClick={handleSubmit}> Continue </Button>
                    </div>
                </Card>
            </div>
        </React.Fragment>
    )
};

export default SetupFlow;