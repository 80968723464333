import { ColorSelectionSceneActions, ColorSelectionSceneActionTypes as Types } from './models';
export interface ColorSelectionSceneState {
    phoneTypeColors: {
        oweInternal: string;
        oweClient: string;
        toCall: string;
        leftWord: string;
        owe: string;
    },
    order: string[],
    isOpen: boolean;
}


export const ColorSelectionSceneInitialState: ColorSelectionSceneState = {
    phoneTypeColors: {
        oweInternal: '#5d7975',
        oweClient: '#db3e00',
        toCall: '#1a237e',
        leftWord: '#c2185b',
        owe: '#4c4c4e'
    },
    order: ['oweInternal', ' oweClient', 'toCall', 'leftWord', 'owe'],
    isOpen: false,

}


// eslint-disable-next-line import/no-anonymous-default-export
export default function (state: ColorSelectionSceneState = ColorSelectionSceneInitialState, action: ColorSelectionSceneActions): ColorSelectionSceneState {
    switch (action.type) {
        case Types.SET_COLOR: {
            return {
                ...state,
                phoneTypeColors: {
                    ...state.phoneTypeColors,
                    [action.phoneType]: action.color
                }
            }
        }
        case Types.SET_ALL_COLOR: {
            return {
                ...state,
                phoneTypeColors: {
                    ...state.phoneTypeColors,
                    ...action.data
                }
            }
        }
        case Types.SET_COLOR_ORDER: {
            return {
                ...state,
                order: [...action.data]
            }
        }
        default:
            return state;
    }
}