export enum LoginSceneActionTypes {
    LOGIN_USER = '[Login Scene] Login User',
    LOGIN_USER_SUCCESS = '[Login Scene] Login User Success',
    LOGIN_USER_FAILURE = '[Login Scene] Login User Failure',
}

export interface LoginUser {
    type: typeof LoginSceneActionTypes.LOGIN_USER;
}

export interface LoginUserSuccess {
    type: typeof LoginSceneActionTypes.LOGIN_USER_SUCCESS;
    isLoggedIn: boolean;
}

export interface LoginUserFailure {
    type: typeof LoginSceneActionTypes.LOGIN_USER_FAILURE;
    error: Error;
}

export type LoginUserActions =
LoginUser | LoginUserSuccess | LoginUserFailure;