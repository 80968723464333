import React, { useState } from 'react'
import { Modal } from 'antd';


interface ModalProps {
    onOk: () => void;
    children: React.ReactNode;
    buttonText: string;
    loading: boolean;
    title?: string;
    width?: number
}



export const RangeModal = ({ onOk, children, buttonText, loading, title, width = 850 }: ModalProps) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        onOk();
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <>
            <div onClick={showModal}>
                {buttonText}
            </div>
            <Modal
                confirmLoading={loading}
                width={width}
                title={title}
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                destroyOnClose
            >
                {children}
            </Modal>
        </>
    );
}