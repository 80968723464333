import { gql } from "@apollo/client";

const SEARCH_CONTACTS_TO_PROCESS = gql`
query SearchContactsToProcess ($name: String!) {
    SearchContactsToProcess(name: $name) {
        name
        contactId
        currentSessionTimestamps
        callLog
        creatorId
        type
        status
        comment
    }
}
`;

const GET_RECORDS = gql`
query GetRecords($supervisorId: String!) {
    GetRecords(supervisorId: $supervisorId) {
        name
        contactId
        timestamp
        type
        comment
        uniqueId
        updated
        transactions {
            transactionId
            uniqueId
            name
            type
            comment
            timestamp
            phoneNumber {
                number
                type
            }
        }
        phoneNumber {
            number
            type
        }
    }
}
`;

const CREATE_RECORDS = gql`
mutation CreateRecord ($record: RecordsInputTypeDef!, $supervisorId: String!) {
    CreateRecord (record: $record, supervisorId: $supervisorId) {
        name
        contactId
        timestamp
        type
        comment
        uniqueId
        updated
        transactions {
            uniqueId
            contactId
            name
            type
            comment
            phoneNumber {
                number
                type
            }
        }
        phoneNumber {
            number
            type
        }
    }
}
`;

const EDIT_RECORD = gql`
mutation EditRecord($record: RecordsInputTypeDef!, $supervisorId: String!, $newTransaction: Boolean) {
    EditRecord(record: $record, supervisorId: $supervisorId, newTransaction: $newTransaction){
        name
        contactId
        timestamp
        type
        comment
        uniqueId
        updated
        transactions {
            transactionId
            uniqueId
            contactId
            name
            type
            comment
            timestamp
            phoneNumber {
                number
                type
            }
        }
        phoneNumber {
            number
            type
        }
    }
}
`;

const SEARCH_RECORDS = gql`
query SearchRecords($query: String!, $supervisorId: String!) {
    SearchRecords(query: $query, supervisorId: $supervisorId){
        name
        contactId
        timestamp
        type
        comment
        uniqueId
        phoneNumber {
            number
            type
        }
        completed
    }
}
`;

const GET_TRANSACTIONS = gql`
query GetTransactions($contactId: String!, $supervisorId: String!) {
    GetTransactions(contactId: $contactId, supervisorId: $supervisorId){
        name
        contactId
        transactionId
        timestamp
        type
        comment
        uniqueId
        phoneNumber {
            number
            type
        }
        completed
    }
}
`;

const DELETE_RECORD = gql`
mutation DeleteRecord($uniqueId: String!) {
    DeleteRecord(uniqueId: $uniqueId)
}
`;

const DELETE_TRANSACTION = gql`
mutation DeleteTransaction($transactionId: String!) {
    DeleteTransaction(transactionId: $transactionId)
}
`;

const DELETE_CONTACT_TO_PROCESS = gql`
mutation DeleteContactToProcess($uniqueId: String!) {
    DeleteContactToProcess(uniqueId: $uniqueId)
}
`;


const exports = {
    SEARCH_CONTACTS_TO_PROCESS,
    DELETE_CONTACT_TO_PROCESS,
    CREATE_RECORDS, GET_RECORDS,
    EDIT_RECORD, DELETE_RECORD,
    GET_TRANSACTIONS,
    SEARCH_RECORDS,
    DELETE_TRANSACTION
};
export default exports;