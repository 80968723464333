import { gql } from "@apollo/client";

const SEARCH_CONTACTS = gql`
query Search ($query: String!, $supervisorId: String!) {
    Search(query: $query, supervisorId: $supervisorId) {
        id
        displayName
        businessPhones
        homePhones
        mobilePhone
    }
}
`;

const exports = { SEARCH_CONTACTS };
export default exports;
