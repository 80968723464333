import React from "react";
import { LoginScene, PhoneSheetScene } from "../scenes/index";
import { Switch } from "react-router-dom";
import { AuthRoute } from "../components/index";
import SetupScene from '../scenes/SetupScene/index';

export const Routes = () => {
  return (
    <Switch>
      <AuthRoute exact path="/phonesheet" component={PhoneSheetScene} />
      <AuthRoute exact path="/setup" component={SetupScene} />
      <AuthRoute exact path="/phonesheet" component={PhoneSheetScene} />
      <AuthRoute exact path="/login" component={LoginScene} />
      <AuthRoute path="/" component={PhoneSheetScene} />
    </Switch>
  );
};
