import User from './User';
import SearchContact from './SearchContacts';
import ContactsToProcess from './ContactsToProcess';
import Outlook from './Outlook';
import Supervisors from './Supervisors';

// Make sure there are no conflicting namespaces in these files.
const exports = {
    ...User,
    ...SearchContact,
    ...ContactsToProcess,
    ...Outlook,
    ...Supervisors
};

export default exports;