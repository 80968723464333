import Modal from 'antd/lib/modal/Modal';
import React from 'react';
import SetupScene from '../../SetupScene';

interface ManagerEditModalProps {
    visible: boolean;
    handleVisibilityChange: () => void;
}

export const ManagerEditModal = ({ visible, handleVisibilityChange }: ManagerEditModalProps) => {
    return (
        <Modal destroyOnClose={true} footer={false} width={800} visible={visible} onCancel={handleVisibilityChange}>
            <SetupScene modalFlag={true} handleVisibilityChange={handleVisibilityChange} />
        </Modal>
    )
}