import React, { useCallback, useEffect, useState } from "react";
import { AutoComplete, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Graphs from "../../graphs";
import { useQuery } from "@apollo/client";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../../rootReducer";
import "./typeahead.css";

interface CallTypeaheadProps {
  onContactSelected: (contact: any, name: string) => void;
}

export const CallTypeahead = ({ onContactSelected }: CallTypeaheadProps) => {
  const [query, setQuery] = useState<string>("");

  const selectedSupervisor = useSelector(
    (state: RootState) => state.PhoneSheetSceneReducer.selectedSupervisor,
    shallowEqual
  );
  const { data: SearchResults, refetch: _refetch } = useQuery(
    Graphs.SEARCH_RECORDS, { variables: { query, supervisorId: selectedSupervisor?.id } }
  );
  const [options, setOptions] = useState([]);

  const refetch = useCallback(() => {
    setTimeout(() => _refetch(), 0);
  }, [_refetch]);

  useEffect(() => {
    if (!SearchResults) return;

    const { SearchRecords: data = [] } = SearchResults;

    const options = data.map((contact: any) => ({
      key: contact.uniqueId,
      value: contact.name,
      label: contact.name,
      id: contact.contactId
    }));


    setOptions(options);
  }, [SearchResults]);

  useEffect(() => {
    if (query.length) {
      refetch();
    }
  }, [query, refetch]);

  const onSelect = (value: string, { id, label }: any) => {
    onContactSelected(id, label);
  };

  const handleSearch = (value: string,) => {
    setQuery(value);
  };

  return (
    <div className="callTypeahead">
      <AutoComplete
        dropdownMatchSelectWidth={252}
        options={options}
        onSelect={onSelect}
        style={{ width: '100%' }}
        onSearch={handleSearch}
      >
        <Input prefix={<SearchOutlined style={{ color: "#c7c7c7" }} />} placeholder="Search completed call logs" bordered={false} />
      </AutoComplete>
    </div>
  );
};
