import { AuthError, UserAgentApplication } from "msal";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import config from "../env-config";
import { loginUserFailure, loginUserSuccess } from "../scenes/LoginScene/services/actions";
import GraphService from "../utils/GraphService";

const useAuthenticationProvider = () => {
    const dispatch = useDispatch();

    const [isAuthed, setIsAuthed] = useState<boolean>(false);
    const [userAgentApplication, setUserAgentApplication] = useState<UserAgentApplication | null>(null);

    const getUserProfile = async () => {
        try {
            const accessToken = await userAgentApplication?.acquireTokenSilent({
                scopes: config.scopes,
            });

            if (accessToken) {
                dispatch(loginUserSuccess());
            }
        } catch (error) { }
    };

    const login = async (prompt = "select_account") => {
        try {
            await userAgentApplication?.loginPopup({ prompt, scopes: config.scopes });
            await getUserProfile();
        } catch (error) {
            // ⬜ TODO: Gracefully handle error
            console.error(`❗ LoginScene:handleLogin: Failed to login ${error}`);
        }
    };

    const logout = async () => {
        await userAgentApplication?.logout();
        // TODO: Dispatch logout.
    };

    useEffect(() => {
        if (!userAgentApplication) {
            (async () => {
                const msalClient = GraphService.getMSALClient();
                setUserAgentApplication(msalClient);
                try {
                    // After initialization is complete, we verify the authentication
                    // token. If still valid (user still logged in), we dispatch login
                    // user successful action.
                    const accessToken = await GraphService.getAccessToken();
                    accessToken && dispatch(loginUserSuccess());
                } catch (error) {
                    if (error instanceof AuthError) {
                        dispatch(loginUserFailure(error));
                    }
                }

                setIsAuthed(true);
            })();
        }
    }, [
        userAgentApplication,
        dispatch
    ]);

    return {
        isAuthed,
        login,
        logout,
        userAgentApplication,
    };
};

export default useAuthenticationProvider;