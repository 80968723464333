import { LoginUserActions, LoginSceneActionTypes as Types } from "./models";

export interface LoginSceneState {
    isLoggedIn: boolean,
    user?: any,
    error?: Error,
}

export const LoginSceneInitialState: LoginSceneState = {
    isLoggedIn: false,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state: LoginSceneState = LoginSceneInitialState, action: LoginUserActions): LoginSceneState {
    switch (action.type) {
        case Types.LOGIN_USER: {
            return {
                ...state,
                isLoggedIn: true,
            }
        }
        case Types.LOGIN_USER_SUCCESS: {
            return {
                ...state,
                isLoggedIn: true,
            }
        }
        case Types.LOGIN_USER_FAILURE: {
            return {
                ...state,
                isLoggedIn: false,
                error: action.error,
            }
        }
        default:
            return state;
    }
}