import { Button, Popconfirm, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { CallInfo, PhoneType } from '../../../models';
import { RootState } from '../../../rootReducer';
import { colorHelper } from '../../../utils/typeMap';
import {
    MinusCircleTwoTone,
} from "@ant-design/icons";
import { formatPhoneNumberType } from '../../../utils/table-utils';

interface ExpandedTableProps {
    recordData: CallInfo[];
    loading?: boolean;
    drawer?: boolean;
    handleDelete?: (transactionId: string, uniqueId: string) => void;
}

export const ExpandedTable = ({ recordData, loading = false, drawer = false, handleDelete }: ExpandedTableProps) => {
    const { phoneTypeColors } = useSelector(
        (state: RootState) => state.ColorSelectionSceneReducer,
        shallowEqual
    );
    const [expandData, setExpandData] = useState<CallInfo[]>();
    useEffect(() => {
        const newData = [...recordData];
        newData.sort((a, b) => b.timestamp - a.timestamp)
        setExpandData(newData);
    }, [recordData])
    const columns: ColumnsType<CallInfo> = [
        {
            title: "Status",
            dataIndex: "type",
            key: "type",
            width: drawer ? '14%' : '9%',
            render: (type: PhoneType, record: CallInfo) => (
                record.completed ? (<div style={{
                    fontWeight: "bold",
                    color: "green",
                    justifyContent: 'center',
                }}>Completed</div>) : (
                        <div
                            style={{
                                fontWeight: "bold",
                                color: phoneTypeColors[type],
                                justifyContent: 'center',
                            }}
                        >
                            {colorHelper(type)}
                        </div>
                    )
            )
        },
        {
            title: 'Time',
            width: drawer ? '18%' : '15%',
            dataIndex: 'timestamp',
            key: 'timestamp',
            sortDirections: ['descend'],
            sorter: {
                compare: (a: any, b: any) =>
                    a -
                    b,
                multiple: 2,
            },
            render: (record: number) => <div>{moment
                .unix(
                    record
                )
                .format("MM/D/yy h:mm A")}</div>
        },
        {
            title: 'Phone',
            width: '15%',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
            render: (item: string, record: CallInfo) => (
                <div style={{ display: 'flex', flexDirection: 'row', whiteSpace: 'nowrap' }}><div style={{ marginRight: 5, color: '#595959' }}>{formatPhoneNumberType(record.phoneNumber.type)}: </div>{' '}{record.phoneNumber.number}</div>
            ),
        },
        { title: 'Note', dataIndex: 'comment', key: 'comment' },
        {
            title: handleDelete ? "Action" : null,
            dataIndex: "action",
            key: "transactionId",
            width: "12%",
            render: (transactionId: string, record: any) => {
                return handleDelete ? (
                    <>
                        <Popconfirm
                            title="Are you sure you want to delete?"
                            onConfirm={() => handleDelete(record.transactionId, record.uniqueId)}
                        >
                            <Button
                                type="link"
                                icon={<MinusCircleTwoTone twoToneColor="#ff0000" />}
                            ></Button>
                        </Popconfirm>
                    </>
                ) : null;
            },
        }
    ]
    return <Table
        rowKey={(record: CallInfo) => `${record.timestamp}+${record.transactionId}`}
        columns={columns}
        dataSource={expandData}
        pagination={false}
        loading={loading} />;
};
