import { useMutation } from '@apollo/client';
import { Spin } from 'antd';
import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SetupFlow, { Supervisors, ValidationStatus } from "../../components/setupFlow";
import Graphs from "../../graphs";
import { RootState } from '../../rootReducer';
import { setSupervisorList, updateIsFirstFlag } from '../PhoneSheetScene/services/actions';

interface SetupSceneProps {
    modalFlag?: boolean
    handleVisibilityChange?: () => void;
}

const SetupScene = ({ modalFlag, handleVisibilityChange }: SetupSceneProps) => {
    const [update, { loading: updateLoading }] = useMutation(Graphs.UPDATE_SUPERVISORS);
    const [updateUser] = useMutation(Graphs.UPDATE_USER);
    const dispatch = useDispatch();
    const history = useHistory();

    const supervisors = useSelector(
        (state: RootState) => state.PhoneSheetSceneReducer.supervisors,
        shallowEqual
    );

    const newSuper = [...supervisors].map((item) => {
        return { ...item, status: ValidationStatus.VALIDATED }
    })

    const handleSubmit = async (supervisors: Supervisors[]): Promise<void> => {
        await update({ variables: { supervisors } }).then(_ => updateUser({ variables: { isFirstFlag: false } })).then(_ => {
            dispatch(updateIsFirstFlag(false))
            dispatch(setSupervisorList(supervisors));
        });

        history.push('/phonesheet')
        if (modalFlag && handleVisibilityChange) {
            handleVisibilityChange()
        }
    };

    if (updateLoading) {
        return <Spin style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} />
    }

    return (
        <SetupFlow modalFlag={modalFlag} supervisors={newSuper} onSubmit={handleSubmit} />
    )
};

export default SetupScene;
