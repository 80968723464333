import { PhoneType } from "../../../models";

// TODO: Refactor to get read of constants and 
//       reducer boilerplate.
export enum ColorSelectionSceneActionTypes {
    SET_COLOR = '[Set Color] Change Color',
    SET_ALL_COLOR = '[Set Color] Set ALL Color',
    SET_COLOR_ORDER = '[Solor Color Order] Set Color order',
}

export interface SetColor {
    type: typeof ColorSelectionSceneActionTypes.SET_COLOR;
    phoneType: PhoneType;
    color: string;
}

export interface SetAllColor {
    type: typeof ColorSelectionSceneActionTypes.SET_ALL_COLOR;
    data: any;
}

export interface SetColorOrder {
    type: typeof ColorSelectionSceneActionTypes.SET_COLOR_ORDER;
    data: any;
}



export type ColorSelectionSceneActions =
    SetColor | SetAllColor | SetColorOrder;