import { PhoneType } from "./PhoneType";

export interface ContactToProcess extends CallInfo {
    transactions: CallInfo[]
}

export interface CallInfo {
    type: PhoneType;
    typeDisplay: string;
    timestamp: number;
    timestampConversion?: string;
    name: string;
    phoneNumber: {
        number: string;
        type: PhoneNumberType;
    };
    phoneNumberConversion?: string;
    comment: string;
    callLog: number[];
    completed?: boolean;
    transactionId?: string;
}

export enum OutlookPhoneNumberLabel {
    BUSINESS_PHONES = 'Business Phones',
    HOME_PHONES = 'Home Phones',
    MOBILE_PHONE = 'Mobile Phone',
}

export enum PhoneNumberType {
    BUSINESS = 'business',
    HOME = 'home',
    MOBILE = 'mobile',
}

