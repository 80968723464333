import React, { useContext, useState, useEffect, useRef } from "react";
import { Form, Select, Input, DatePicker, AutoComplete, message } from "antd";
import { FormDataSource, PhoneType } from "../models";
import { ColumnDataIndices, getPhoneNumberType } from "../utils/table-utils";
import moment from "moment";
import { getContactInformation } from "../utils/contactInformation";
import { useQuery } from "@apollo/client";
import Graphs from '../graphs';
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../rootReducer";

interface EditableCellProps {
  title: string;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: string;
  record: any;
  handleSave: (record: FormDataSource) => void;
}

interface EditableRowProps {
  index: number;
}

const EditableContext = React.createContext<any>(undefined);

export const EditableRow: React.FC<EditableRowProps> = ({
  index,
  ...props
}) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableStatus = (
  record: FormDataSource,
  dataIndex: string,
  title: string,
  save: (editedTime: moment.Moment) => Promise<void>,
  inputRef: React.MutableRefObject<any>
) => {
  return (
    <Form.Item
      id={`${record.uniqueId}+${record.type}`}
      initialValue={record.type}
      name={dataIndex}
      style={{ margin: 0 }}
      rules={[
        {
          required: true,
          message: `Please Input ${title}!`,
        },
      ]}
    >
      <Select
        style={{ width: 150 }}
        placeholder="Call Type"
        onSelect={() => save(moment())}
        onBlur={() => save(moment())}
        ref={inputRef}
      >
        <Option value={PhoneType.LEFT_WORD}>Left Word</Option>
        <Option value={PhoneType.OWE}>Owe</Option>
        <Option value={PhoneType.TO_CALL}>To Call</Option>
        <Option value={PhoneType.OWE_CLIENT}>Owe Client</Option>
        <Option value={PhoneType.OWE_INTERNAL}>Owe Internal</Option>
      </Select>
    </Form.Item>
  );
};
const EditableContact = (
  record: FormDataSource & { id: any },
  dataIndex: string,
  title: string,
  phoneOptions: any[],
  save: (x: string, item: any) => Promise<void>,
  inputRef: React.MutableRefObject<any>,
  toggle: () => void
) => {
  return (
    <Form.Item
      name={[dataIndex, 'number']}
      id={`${record.uniqueId}+${record.phoneNumber.number}+${record.phoneNumber.type}`}
      style={{ margin: 0 }}
      initialValue={record.phoneNumber.number}
      rules={[
        {
          required: true,
          message: `Please Input ${title}!`,
        },
      ]}
    >
      <AutoComplete
        ref={inputRef}
        options={phoneOptions}
        onBlur={toggle}
        onSelect={(label: string, option: any) => save(label, option)}
        dropdownMatchSelectWidth={180}
      />
    </Form.Item>)
};
const EditableNote = (
  record: FormDataSource,
  dataIndex: string,
  title: string,
  save: () => Promise<void>,
  inputRef: React.MutableRefObject<any>
) => {
  return (
    <Form.Item
      id={`${record.uniqueId}+${record.comment}`}
      initialValue={record.comment}
      name={dataIndex}
      style={{ margin: 0 }}
      rules={[
        {
          required: true,
          message: `Please Input ${title}!`,
        },
      ]}
    >
      <Input.TextArea
        ref={inputRef}
        onBlur={() => save()}
        onPressEnter={() => save()}
      />
    </Form.Item>
  );
};

export const EditableTime = (
  record: FormDataSource,
  dataIndex: string,
  title: string,
  save: (time?: moment.Moment) => Promise<void>,
  inputRef: React.MutableRefObject<any>,
  toggle: () => void
) => {
  return (
    <>
      <Form.Item
        id={`${record.uniqueId}+${record.timestamp}`}
        initialValue={moment.unix(
          record.timestamp
        )}
        style={{ margin: 0, minWidth: 200 }}
        rules={[
          {
            required: true,
            message: `Please Input ${title}!`,
          },
        ]}
      >
        <DatePicker
          defaultValue={moment.unix(record.timestamp)}
          style={{ margin: 0 }}
          ref={inputRef}
          showTime={{ format: "h:mm a" }}
          format="MM-DD-YYYY h:mm a"
          onOk={(time: moment.Moment) => save(time)}
          onBlur={() => toggle()}
        />
      </Form.Item>
    </>
  );
};

export const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const [phoneOptions, setPhoneOptions] = useState<any>([]);

  const inputRef = useRef<any>();
  const form = useContext(EditableContext);

  const selectedSupervisor = useSelector(
    (state: RootState) => state.PhoneSheetSceneReducer.selectedSupervisor,
    shallowEqual
  );


  const { data, loading } = useQuery<any>(Graphs.GET_CONTACT_BY_ID, {
    variables: {
      contactId: (record && record.contactId) || '',
      supervisorId: selectedSupervisor?.id,
    },
    skip: typeof record === 'undefined' || dataIndex !== ColumnDataIndices.CONTACT || !editing
  });

  useEffect(() => {
    if (editing && !loading) {
      inputRef.current.focus();
    }
  }, [editing, loading]);

  useEffect(() => {
    if (data) {
      const { Outlook = {} } = data || {};
      const { ContactById = {} } = Outlook;
      const results = getContactInformation(ContactById);
      setPhoneOptions(results);
    }
  }, [data]);

  if (loading) { return null };



  const toggleFocus = () => {
    setEditing((prev) => !prev);
  };
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const handleContactSave = async (label: any, value: any) => {
    //TODO Fix this Type
    const newCall: any = {
      uniqueId: record.uniqueId,
      type: record.type,
      timestamp: record.timestamp,
      name: record.name,
      phoneNumber: { number: record.phoneNumber.number, type: value.type },
      comment: record.comment,
      contactId: record.contactId,
      completed: false,
      updated: record.updated,
    };
    try {
      const values = await form.validateFields();
      if (values.phoneNumber) {
        values.phoneNumber.type = getPhoneNumberType(value.type);
      }
      toggleEdit();
      handleSave({ ...newCall, ...values });
    } catch (errInfo) {
      message.error("Save failed");
    }
  }

  const save = async (editTime?: moment.Moment) => {
    //TODO Fix this Type
    const newCall: any = {
      uniqueId: record.uniqueId,
      type: record.type,
      timestamp: record.timestamp,
      name: record.name,
      phoneNumber: { number: record.phoneNumber.number, type: record.phoneNumber.type },
      comment: record.comment,
      contactId: record.contactId,
      completed: false,
      updated: record.updated,
    };
    try {
      const values = await form.validateFields();
      if (editTime) {
        newCall.timestamp = moment(editTime).unix();

      }
      toggleEdit();
      handleSave({ ...newCall, ...values });
    } catch (errInfo) {
      message.error("Save failed");
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <>
        {dataIndex === ColumnDataIndices.TYPE
          ? EditableStatus(record, dataIndex, title, save, inputRef)
          : null}
        {dataIndex === ColumnDataIndices.CONTACT
          ? EditableContact(record, dataIndex, title, phoneOptions, handleContactSave, inputRef, toggleFocus)
          : null}
        {dataIndex === ColumnDataIndices.COMMENT
          ? EditableNote(record, dataIndex, title, save, inputRef)
          : null}
        {dataIndex === ColumnDataIndices.TIME
          ? EditableTime(record, dataIndex, title, save, inputRef, toggleFocus)
          : null}
      </>
    ) : (
        <div
          className="editable-cell-value-wrap"
          onClick={toggleEdit}
        >
          {children}
        </div>
      );
  }

  return <td {...restProps}>{childNode}</td>;
};

const { Option } = Select;
