import React from 'react';
import { FormDataSource, PhoneType, PhoneNumberType, OutlookPhoneNumberLabel } from "../models";
import { colorHelper } from "../utils/typeMap";

export const filterItem = (id: string, dataSource: FormDataSource[]) => {
  const dataHolder = [...dataSource];
  return dataHolder.filter((item) => item.uniqueId !== id)
};

export const getTabelRowColor = (callType: PhoneType): string => {
  switch (callType) {
    case PhoneType.OWE_INTERNAL: {
      return 'rgb(71,105,94)'
    }
    default: {
      return ''
    }
  }
}

export const getRowColor = (phoneType: PhoneType) => {
  switch (phoneType) {
    case PhoneType.OWE_INTERNAL: {
      return 'oweInternal'
    }
    case PhoneType.OWE_CLIENT: {
      return 'oweClient'
    }
    case PhoneType.TO_CALL: {
      return 'toCall'
    }
    case PhoneType.LEFT_WORD: {
      return 'leftWord'
    }
  }
}

export const getPhoneNumberType = (phoneNumberType?: OutlookPhoneNumberLabel) => {
  switch (phoneNumberType) {
    case OutlookPhoneNumberLabel.BUSINESS_PHONES: {
      return 'business'
    }
    case OutlookPhoneNumberLabel.HOME_PHONES: {
      return 'home'
    }
    case OutlookPhoneNumberLabel.MOBILE_PHONE: {
      return 'mobile'
    }
    default: {
      return ''
    }
  }
}

export const formatPhoneNumberType = (phoneNumberType?: PhoneNumberType) => {
  switch (phoneNumberType) {
    case PhoneNumberType.BUSINESS: {
      return 'Office'
    }
    case PhoneNumberType.HOME: {
      return 'Home'
    }
    case PhoneNumberType.MOBILE: {
      return 'Cell'
    }
    default: {
      return ''
    }
  }
}

export function formatPhoneNumber(phoneNumberString: string) {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    var intlCode = (match[1] ? '+1 ' : '')
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
  }
  return phoneNumberString
}

export enum ColumnDataIndices {
  TYPE = "type",
  TIME = "timestamp",
  CONTACT = 'phoneNumber',
  COMMENT = 'comment'
}

export const exportMockData: any[] = [
  {
    name: 'Jeff',
    type: PhoneType.LEFT_WORD,
    timestampConversion: '12/13/2020 8:50 PM',
    phoneNumber: '(555)555-5555',
    comment: 'Here is a note',
    uniqueId: '1',
    phoneNumberConversion: '(555)555-5555',

  },
  {
    name: 'Dave',
    type: PhoneType.OWE,
    timestampConversion: '12/13/2020 8:50 PM',
    phoneNumber: '(555)555-5555',
    comment: 'Here is a note',
    uniqueId: '2',
    phoneNumberConversion: '(555)555-5555',


  },
  {
    name: 'Brian',
    type: PhoneType.OWE_CLIENT,
    timestampConversion: '12/13/2020 8:50 PM',
    phoneNumber: '(555)555-5555',
    comment: 'Here is a note',
    uniqueId: '3',
    phoneNumberConversion: '(555)555-5555',

  },
  {
    name: 'Kelly',
    type: PhoneType.OWE_INTERNAL,
    timestampConversion: '12/13/2020 8:50 PM',
    phoneNumber: '(555)555-5555',
    comment: 'Here is a note',
    uniqueId: '4',
    phoneNumberConversion: '(555)555-5555',

  },
  {
    name: 'Susan',
    type: PhoneType.TO_CALL,
    timestampConversion: '12/13/2020 8:50 PM',
    phoneNumber: '(555)555-5555',
    comment: 'Here is a note',
    uniqueId: '5',
    phoneNumberConversion: '(555)555-5555',
  },
]


export const createExportTable = (dataSource: FormDataSource[]) => {
  const headers = (<thead>
    <tr style={{ border: '1px solid #dddddd' }}>
      <th style={{ border: '1px solid #dddddd', color: 'gray', padding: 5 }}>Name</th>
      <th style={{ border: '1px solid #dddddd', color: 'gray', padding: 5 }}>Kind</th>
      <th style={{ border: '1px solid #dddddd', color: 'gray', padding: 5 }}>Time</th>
      <th style={{ border: '1px solid #dddddd', color: 'gray', padding: 5 }}>Phone</th>
      <th style={{ border: '1px solid #dddddd', color: 'gray', padding: 5 }}>Notes</th>
    </tr>
  </thead>)

  const body = dataSource.map(data => {
    return (
      <tr key={data.uniqueId}>
        <td style={{ border: '1px solid #dddddd', padding: 5, whiteSpace: 'nowrap' }}>{data.name}</td>
        <td style={{ border: '1px solid #dddddd', padding: 5, whiteSpace: 'nowrap' }}>{colorHelper(data.type)}</td>
        <td style={{ border: '1px solid #dddddd', padding: 5, whiteSpace: 'nowrap' }}>{data.timestampConversion}</td>
        <td style={{ border: '1px solid #dddddd', padding: 5, whiteSpace: 'nowrap' }}>{data.phoneNumberConversion}</td>
        <td style={{ border: '1px solid #dddddd', padding: 5 }}>{data.comment}</td>
      </tr>
    )

  })


  return (<div>
    <table style={{ borderCollapse: 'collapse', width: '100%' }}>
      {headers}
      <tbody>
        {body}
      </tbody>
    </table>
  </div>)
}

export const tableExportSorter = (a: FormDataSource, b: FormDataSource) => {
  if (a.rank === b.rank) {
    return b.timestamp - a.timestamp;
  }
  return a.rank - b.rank
};