import { Button } from "antd";
import React, { useState } from "react";
import { PhoneType } from "../../../models";
import { GithubPicker } from "react-color";
import { DisplayWrapper } from "../../../components/DisplayWrapper";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../rootReducer";
import { setTypeColor } from "../services/actions";

import { colorHelper } from "../../../utils/typeMap";
import { colors } from './constants';

interface ColorSelectionProps {
  phoneType: PhoneType;
  handleColorSelect: (type: string, color: string) => void,
}

const styles = {
  color: {
    width: "36px",
    height: "14px",
    borderRadius: "2px",
  },
  swatch: {
    padding: "5px",
    background: "#fff",
    borderRadius: "1px",
    boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
    display: "inline-block",
    cursor: "pointer",
  },
  container: {
    display: "flex",
    marginBottom: 10,
  },
};


export const ColorSelection = ({ phoneType, handleColorSelect }: ColorSelectionProps) => {
  const [showPicker, setShowPicker] = useState(false);
  const dispatch = useDispatch();

  const { phoneTypeColors } = useSelector(
    (state: RootState) => state.ColorSelectionSceneReducer,
    shallowEqual
  );

  const onColorSelect = async (phoneType: PhoneType, selectedColor: string) => {
    dispatch(setTypeColor(phoneType, selectedColor));
    handleColorSelect(phoneType, selectedColor);
  };

  return (
    <div>
      <div style={styles.container}>
        <div onClick={() => setShowPicker(!showPicker)} style={{ ...styles.swatch }}>
          <div
            style={{
              ...styles.color,
              backgroundColor: phoneTypeColors[phoneType],
            }}
          />
        </div>
        <Button onClick={() => setShowPicker(!showPicker)} type="link">
          {colorHelper(phoneType)}
        </Button>
      </div>

      <DisplayWrapper display={showPicker}>
        <GithubPicker
          colors={colors}
          onChangeComplete={(color) => onColorSelect(phoneType, color.hex)}
        />
      </DisplayWrapper>
    </div>
  );
};
