import React, { useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import {
  Redirect,
  Route,
  RouteComponentProps,
  useHistory,
  useLocation,
} from "react-router-dom";
import { RootState } from "../rootReducer";
import { LoginScene } from "../scenes";

interface AuthRouteProps {
  component:
  | React.ComponentType<RouteComponentProps<any>>
  | React.ComponentType<any>;
  exact?: boolean;
  path: string;
}

export const AuthRoute = ({
  component,
  path,
  exact = false,
}: AuthRouteProps) => {
  const isLoggedIn = useSelector(
    (state: RootState) => state.LoginSceneReducer.isLoggedIn,
    shallowEqual
  );

  const isFirstTime = useSelector(
    (state: RootState) => state.PhoneSheetSceneReducer.isFirstTime,
    shallowEqual
  );

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (typeof isFirstTime === 'undefined') { return; }

    if (isLoggedIn) {
      if (isFirstTime) {
        history.push('/setup');
        return;
      }
      // history.push('/phonesheet');
    }
  }, [isLoggedIn, isFirstTime, history]);

  if (location.pathname === path) {
    return isLoggedIn ? (
      <Route exact={exact} path={path} component={component} />
    ) : (
        <>
          <Route exact={exact} path={'/login'} component={LoginScene} />
          <Route path={'/'} component={LoginScene} />
        </>
      );
  }
  return <Redirect to="/" />;
};
