import React, { Fragment, useEffect } from "react";
import "./App.css";
import { Routes } from "./routes/routeConfig";
// import { UserAgentApplication } from 'msal';
import { AppLayout } from "./components/index";
import { useAuthenticationProvider } from "./hooks";
import { useLazyQuery } from "@apollo/client";
import Graphs from './graphs'
import { useDispatch } from "react-redux";
import { setUser } from "./scenes/PhoneSheetScene/services/actions";

function App() {
  const { isAuthed } = useAuthenticationProvider();
  const [GetUser, { data: userData }] = useLazyQuery(Graphs.GET_USER);
  const dispatch = useDispatch();

  useEffect(() => {
    GetUser()
  }, [GetUser])

  useEffect(() => {
    dispatch(setUser(userData?.User))
  }, [userData, dispatch])


  return (
    <div className="App">
      <AppLayout>{isAuthed ? <Routes /> : <Fragment />}</AppLayout>
    </div>
  );
}

export default App;
