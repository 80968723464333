import { LoginSceneActionTypes, LoginUser, LoginUserFailure, LoginUserSuccess } from './models';

export const loginUser = (): LoginUser => ({
    type: LoginSceneActionTypes.LOGIN_USER,
})

export  const loginUserSuccess = (): LoginUserSuccess => ({
    type: LoginSceneActionTypes.LOGIN_USER_SUCCESS,
    isLoggedIn : true,
})

export const loginUserFailure = (error: Error): LoginUserFailure => ({
    type: LoginSceneActionTypes.LOGIN_USER_FAILURE,
    error: error,
})