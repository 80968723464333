import { PhoneType } from "../models";

export const colorHelper = (phoneType: PhoneType) => {
    switch (phoneType) {
      case PhoneType.LEFT_WORD: {
        return "LW";
      }
      case PhoneType.TO_CALL: {
        return "To Call";
      }
      case PhoneType.OWE_CLIENT: {
        return "Owe Client";
      }
      case PhoneType.OWE_INTERNAL: {
        return "Owe Internal";
      }
      case PhoneType.OWE: {
        return "Owe";
      }
    }
  };