/* eslint-disable react-hooks/exhaustive-deps */

import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { AutoComplete, Input } from 'antd';
import { InputProps } from 'antd/lib/input';
import Select, { OptionProps, SelectProps } from 'antd/lib/select';
import countries, { Country } from './countries';
import { formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input'
import PhoneTypes from './phoneTypes';

const { Option } = Select;

interface PropTypes extends Omit<InputProps, 'value' | 'onChange'> {
    onChange?: (value: CountryPhoneInputValue) => void;
    value?: CountryPhoneInputValue;
    selectProps?: SelectProps<any>;
    optionProps?: OptionProps;
    showFlags?: boolean;
    defaultCountry?: string;
}

export interface PhoneType {
    label: string;
    value: string;
};

export type CountryPhoneInputValue = {
    code?: number;
    phone?: string;
    short?: string;
    intlNumber?: string;
    isValidNumber?: boolean;
    type?: PhoneType;
};

function CountryPhoneInput({
    onChange,
    value,
    showFlags = false,
    defaultCountry = 'US',
    selectProps,
    ...props
}: PropTypes) {
    const _defaultCountry: Country | undefined = useMemo(() => {
        return countries.find((c) => c.short === defaultCountry);
    }, []);

    const [country, setCountry] = useState<Country | undefined>(_defaultCountry);
    const [phone, setPhone] = useState<string | undefined>();
    const [type, setType] = useState<PhoneType>(PhoneTypes[1]);
    const [prefixOptions, setPrefixOptions] = useState<any[]>([]);

    useEffect(() => {
        if (value && value.type) {
            setType(value.type);
        }
    }, []);

    useEffect(() => {
        setPrefixOptions(countries.map((item) => {
            return {
                key: item.short,
                value: `+${item.phoneCode}`,
                label: `+${item.phoneCode} ${item.en}`,
            };
        }));
    }, [countries])

    useEffect(() => {
        if (value !== undefined) {
            if (value.short) {
                setCountry(countries.find((c) => c.short === value.short));
            } else {
                setCountry(countries.find((c) => Number(c.phoneCode) === value.code));
            }
            setPhone(value.phone);
        }
    }, [value]);

    const triggerChange = useCallback(
        (phone?: string, country?: Country) => {
            const result: CountryPhoneInputValue = {
                phone,
                isValidNumber: isValidPhoneNumber(`+${country?.phoneCode} ${phone}`),
                intlNumber: formatPhoneNumberIntl(`+${country?.phoneCode}${phone}`),
                code: country && Number(country.phoneCode),
                short: country && country.short,
                type,
            };
            onChange?.(result);
        },
        [onChange]
    );

    const handleCountryChange = useCallback(
        (value: string) => {
            const c = countries.find((c) => c.short === value || `+${c.phoneCode}` === value);
            if (!c) {
                console.log(`Country not found: ${value}`);
                return;
            }
            setCountry(c);
            triggerChange(phone, c);
        },
        [setCountry, triggerChange, phone]
    );

    const handlePhoneChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const currentValue = e.target.value;
            setPhone(currentValue);
            triggerChange(currentValue, country);
        },
        [setPhone, country, triggerChange]
    );

    const handlePhoneTypeChange = useCallback(value => {
        const _type = PhoneTypes.find((t: PhoneType) => t.value === value);
        _type && setType(_type);
    }, []);

    return (
        <Input.Group compact>
            <Select value={type.value} onChange={handlePhoneTypeChange} style={{ width: '20% ' }}>
                {PhoneTypes.map(type => <Option value={type.value}> {type.label} </Option>)}
            </Select>
            <AutoComplete
                {...selectProps}
                defaultValue={'+1'}
                dropdownMatchSelectWidth={200}
                style={{ width: '25% ' }}
                options={prefixOptions}
                onSearch={handleCountryChange}
                onSelect={handleCountryChange}
                filterOption={
                    (value, option) => {
                        const { label = '' } = option as any;
                        return label.includes(value);
                    }
                }
            />
            <Input
                {...props}
                style={{ width: '55% ' }}
                value={value && value.phone}
                onChange={handlePhoneChange}
            ></Input>
        </Input.Group >
    );
}

export { CountryPhoneInput };
export default CountryPhoneInput;