/* eslint-disable import/no-anonymous-default-export */
import { gql } from "@apollo/client";

const UPDATE_SUPERVISORS = gql`
  mutation UpdateSupervisors($supervisors: [SupervisorInputTypeDef]!) {
    UpdateSupervisors(supervisors: $supervisors)
  }
`;

export default {
  UPDATE_SUPERVISORS,
};
