import {
    GetProfileInfoActionTypes,
    GetProfileInfo,
    GetProfileInfoFailure,
    GetProfileInfoSuccess,
    SetSupervisor,
    Supervisor,
    SetAllSupervisors,
    SetUser,
    SetFirstFlag
} from './models';

export const getProfileInfo = (): GetProfileInfo => ({
    type: GetProfileInfoActionTypes.GET_PROFILE_INFO,
})


export const getProfileInfoSuccess = (data: any): GetProfileInfoSuccess => ({
    type: GetProfileInfoActionTypes.GET_PROFILE_INFO_SUCCESS,
    result: data,
})

export const getProfileInfoFailure = (error: Error): GetProfileInfoFailure => ({
    type: GetProfileInfoActionTypes.GET_PROFILE_INFO_FAILURE,
    error: error,
})

export const selectSupervisor = (supervisor: Supervisor): SetSupervisor => ({
    type: GetProfileInfoActionTypes.SET_SUPERVISOR,
    supervisor
})

export const setSupervisorList = (supervisors: Supervisor[]): SetAllSupervisors => ({
    type: GetProfileInfoActionTypes.SET_ALL_SUPERVISORS,
    supervisors
})

export const setUser = (user: any): SetUser => ({
    type: GetProfileInfoActionTypes.SET_USER,
    user
})

export const updateIsFirstFlag = (flag: boolean): SetFirstFlag => ({
    type: GetProfileInfoActionTypes.SET_FIRST_FLAG,
    flag,
})

