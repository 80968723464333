import React, { useState } from "react";
import { SettingFilled } from "@ant-design/icons";
import { Button, Drawer, Layout as AntLayout, Tooltip } from "antd";
import { Content, Header } from "antd/lib/layout/layout";
import { rangeLogo } from "../assets/index";
import { createUseStyles } from "react-jss";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../rootReducer";
import { DisplayWrapper } from "./DisplayWrapper";
import { useAuthenticationProvider } from "../hooks";
import { ColorSelectionScene } from "../scenes";
import { LogoutOutlined } from "@ant-design/icons";
import { MenuSelect } from "./MenuSelect";
import { ManagerEditModal } from "../scenes/PhoneSheetScene/components/ManagerEdit";

interface LayoutProps {
  children: React.ReactChild;
}

const useStyles = createUseStyles({
  container: {
    height: "100%",
    display: "flex",
    flex: 1,
  },
  content: {
    height: "100%",
    width: "100%",
    padding: "10px",
  },
});

export const AppLayout = ({ children }: LayoutProps) => {
  const classes = useStyles();
  const { logout } = useAuthenticationProvider();
  const [visible, setVisible] = useState(false);

  const { isLoggedIn } = useSelector(
    (state: RootState) => state.LoginSceneReducer,
    shallowEqual
  );
  const isFirstTime = useSelector(
    (state: RootState) => state.PhoneSheetSceneReducer.isFirstTime,
    shallowEqual
  );
  const [sliderState, setSliderState] = useState(false);
  const handleStateSlideChange = () => {
    setSliderState((prev) => !prev);
  };

  const handleManagerButton = () => {
    setVisible((prev) => !prev);
    setSliderState(false);
  }
  return (
    <AntLayout className={classes.container}>
      <DisplayWrapper display={isLoggedIn && !isFirstTime}>
        <>
          <Header
            style={{
              padding: 0,
              height: 80,
              backgroundColor: "black",
              display: "flex",
              flex: 1,
            }}
          >
            <div style={{ flex: 1 }}></div>
            <div style={{
              flex: 1,
            }}>
              <img
                src={rangeLogo}
                alt=""
                style={{
                  width: 200,
                  height: 80,
                }}
              />
            </div>
            <div style={{ display: 'flex', paddingRight: 10, alignItems: 'center' }}>
              <div>
                <>
                  <MenuSelect />
                  <Button onClick={handleStateSlideChange} type="text">
                    <SettingFilled style={{ color: "white" }} />
                  </Button>
                  <Drawer
                    title="Preferences"
                    width={300}
                    placement="right"
                    closable={true}
                    onClose={handleStateSlideChange}
                    visible={sliderState}
                    keyboard={true}
                    destroyOnClose={true}
                  >
                    <h4> Manager Preferences: </h4>

                    <Button style={{ marginBottom: 20, marginTop: 5 }} type='primary' onClick={handleManagerButton}>Edit Managers</Button>
                    <ColorSelectionScene />
                  </Drawer>
                </>
              </div>

              <ManagerEditModal
                handleVisibilityChange={handleManagerButton}
                visible={visible} />

              <div>
                <Tooltip placement="bottomRight" title="Logout" color="blue">
                  <Button
                    onClick={logout}
                    type="text"
                    style={{ color: "white" }}
                    icon={<LogoutOutlined />}
                  ></Button>
                </Tooltip>
              </div>
            </div>
          </Header>
        </>
      </DisplayWrapper>

      <Content className={classes.content}>{children}</Content>
    </AntLayout>
  );
};
