import * as React from 'react';
import { useState } from "react";
import { Divider, Table } from "antd";
import { SortableContainer as HOCSortableContainer, SortableElement as HOCSortableElement, SortableHandle, SortEnd } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { MenuOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { ColorSelection } from "./components/ColorSelection";
import { PhoneType } from "../../models";
import Graphs from '../../graphs';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { setColorOrder } from './services/actions';

export interface ColorSelectionType {
  key: string,
  color: JSX.Element,
  index: number,
}

const DragHandle = SortableHandle(() => (
  <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />
));

const SortableElement = HOCSortableElement((props: any): any => <tr {...props} />);
const SortableContainer = HOCSortableContainer((props: any): any => <tbody {...props} />);

const getValidPayload = (phoneTypeColors: any) => {
  const validKeys = ['oweInternal', 'oweClient', 'toCall', 'leftWord', 'owe'];
  const validPayload = validKeys.reduce((acc: any, key: string) => {
    if ((phoneTypeColors as any)[key]) {
      acc[key] = (phoneTypeColors as any)[key];
    }
    return acc;
  }, {});

  return validPayload;
};


export const ColorSelectionScene = () => {
  // #region Color Selection State
  const dispatch = useDispatch();
  const [dataSource, setDataSource] = useState<ColorSelectionType[]>([]);
  const { order: storeOrder, phoneTypeColors } = useSelector(
    (state: RootState) => state.ColorSelectionSceneReducer,
    shallowEqual
  );
  const [updatePreferences, { loading: updateLoading }] = useMutation(Graphs.UPATE_USER_PREFERENCES);

  const columns: any = [
    {
      title: 'Color',
      dataIndex: 'color',
      className: 'drag-visible',
      render: (color: JSX.Element) => color
    },
    {
      title: 'Sort',
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      render: () => <DragHandle />,
    },
  ];
  // #endregion Color Selection State

  //#region Hooks
  React.useEffect(() => {
    // TODO: Ideally these type of side-effects should be handled
    //       in our actions. As future task side-effefcts (such as
    //         updating DB) should be handled in our action
    const onColorSelect = (phoneType: any, selectedColor: any) => {
      const validPayload = getValidPayload(phoneTypeColors);
      const newColor = { [phoneType]: selectedColor };
      const contactColorTypes = { order: storeOrder, ...validPayload, ...newColor };

      updatePreferences({ variables: { preferences: { contactColorTypes } } });
    };

    const data: ColorSelectionType[] = [
      {
        key: PhoneType.LEFT_WORD,
        index: storeOrder.indexOf(PhoneType.LEFT_WORD),
        color: <ColorSelection handleColorSelect={onColorSelect} phoneType={PhoneType.LEFT_WORD} />
      },
      {
        key: PhoneType.OWE,
        index: storeOrder.indexOf(PhoneType.OWE),
        color: <ColorSelection handleColorSelect={onColorSelect} phoneType={PhoneType.OWE} />
      },
      {
        key: PhoneType.TO_CALL,
        index: storeOrder.indexOf(PhoneType.TO_CALL),
        color: <ColorSelection handleColorSelect={onColorSelect} phoneType={PhoneType.TO_CALL} />
      },
      {
        key: PhoneType.OWE_CLIENT,
        index: storeOrder.indexOf(PhoneType.OWE_CLIENT),
        color: <ColorSelection handleColorSelect={onColorSelect} phoneType={PhoneType.OWE_CLIENT} />
      },
      {
        key: PhoneType.OWE_INTERNAL,
        index: storeOrder.indexOf(PhoneType.OWE_INTERNAL),
        color: <ColorSelection handleColorSelect={onColorSelect} phoneType={PhoneType.OWE_INTERNAL} />
      },
    ];

    data.sort(({ index: indexA }, { index: indexB }) => indexA - indexB);

    setDataSource(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //#endregion

  //#region Helpers
  const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMove([].concat(((dataSource as any))), oldIndex, newIndex).filter(el => !!el);
      const newOrder = Object.values(newData).map(({ key }): string => key);

      // Make call to store new order.
      const validPayload = getValidPayload(phoneTypeColors);
      const contactColorTypes = { order: newOrder, ...validPayload };
      updatePreferences({ variables: { preferences: { contactColorTypes } } });

      dispatch(setColorOrder(newOrder));
      setDataSource(newData);
    }
  };

  const DraggableBodyRow = ({ className, style, ...restProps }: any) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = (dataSource as ColorSelectionType[]).findIndex((x: ColorSelectionType) => x.index === restProps['data-row-key']);
    return (<SortableElement index={index} {...restProps} />);
  };

  const DraggableContainer = (props: any) => {
    return <SortableContainer
      useDragHandle
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  };

  //#endregion
  return (
    <>
      <div>
        <h4> Color Preferences: </h4>

        <Table
          loading={updateLoading}
          showHeader={false}
          pagination={false}
          dataSource={dataSource}
          columns={columns}
          rowKey="index"
          components={{
            body: {
              wrapper: DraggableContainer,
              row: DraggableBodyRow,
            },
          }}
        />
      </div>
      <Divider />
    </>
  );
};