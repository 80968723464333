import { Button, Card } from "antd";
import React from "react";
import { rangeLogo } from "../../assets";

import { createUseStyles } from "react-jss";
import { useAuthenticationProvider } from "../../hooks";
import { useLazyQuery } from "@apollo/client";
import Graphs from "../../graphs";
import { useDispatch } from "react-redux";
import { setUser } from "../PhoneSheetScene/services/actions";

const useStyles = createUseStyles({
  container: {
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    display: "flex",
    height: "100%",
  },
  padding: {
    padding: 10,
    width: "360px",
    height: "192px",
    borderRadius: "16px",
    marginRight: "24px",
    boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  button: {
    marginTop: 10,
  },
});
export const LoginScene = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { login: AuthenticationLogin } = useAuthenticationProvider();
  const [GetUser, { data: userData }] = useLazyQuery(Graphs.GET_USER);

  const handleLogin = async () => {
    await AuthenticationLogin().then(() => {
      GetUser();
    }).then(() => dispatch(setUser(userData?.User))
    );
  };

  return (
    <div className={classes.container}>
      <Card
        className={classes.padding}
        cover={
          <img src={rangeLogo} alt="" style={{ width: 150, height: 50 }} />
        }
      >
        <div style={{}}>
          <Button
            type="primary"
            className={classes.button}
            onClick={handleLogin}
          >
            Login
          </Button>
        </div>
      </Card>
    </div>
  );
};
