import React, { useEffect, useState } from "react";
import { Table, Popconfirm, Button, Drawer, Form, Dropdown, Menu, Checkbox, Modal, Divider, message, Typography } from "antd";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { CSVLink } from "react-csv";
import moment from "moment";
import ReactDOMServer from 'react-dom/server';
import {
  DownloadOutlined,
  CheckCircleTwoTone,
  MinusCircleTwoTone,
  ExclamationCircleOutlined
} from "@ant-design/icons";
import { CallLogForm } from "../../components/CallLogForm";
import {
  DisplayWrapper,
  RangeModal,
  EmailOptionSelect,
} from "../../components/index";
import { RootState } from "../../rootReducer";
import { EditableCell, EditableRow } from "../../components/EditableCell";
import { FormDataSource, PhoneType, StaleRecord } from "../../models/index";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { CallTypeahead } from "../CallTypeahead/CallTypeahead";
import { rangeLogo } from "../../assets/index";
import { setAllColor, setColorOrder } from "../ColorSelectionScene/services/actions";
import { colorHelper } from "../../utils/typeMap";
import Graphs from "../../graphs";
import { createExportTable, formatPhoneNumberType, tableExportSorter } from "../../utils/table-utils";
import services from "../../utils/GraphService";
import { ExpandedTable } from './components/Expandedtable';
import { selectSupervisor, setSupervisorList } from "./services/actions";
import { createUseStyles } from "react-jss";

const styles = createUseStyles({
  headerContainer: {
    display: 'flex',
    paddingBottom: '10px',
    paddingTop: '40px',
    alignItems: 'stretch',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
  }
});

export const PhoneSheetScene = () => {
  const dispatch = useDispatch();
  const classes = styles();

  const { order } = useSelector(
    (state: RootState) => state.ColorSelectionSceneReducer,
    shallowEqual
  );

  const selectedSupervisor = useSelector(
    (state: RootState) => state.PhoneSheetSceneReducer.selectedSupervisor,
    shallowEqual
  );

  const supervisors = useSelector(
    (state: RootState) => state.PhoneSheetSceneReducer.supervisors,
    shallowEqual
  );

  const { phoneTypeColors } = useSelector(
    (state: RootState) => state.ColorSelectionSceneReducer,
    shallowEqual
  );
  const defaultOptions = [PhoneType.LEFT_WORD, PhoneType.OWE, PhoneType.OWE_CLIENT, PhoneType.OWE_INTERNAL, PhoneType.TO_CALL];
  const { loading: loadingUser, data: userData, refetch: refetchUser } = useQuery(Graphs.GET_USER);
  const { loading: loadingRecords, data: recordsData, refetch: refetchRecords } = useQuery(Graphs.GET_RECORDS, { variables: { supervisorId: selectedSupervisor?.id } });
  const [LoadTransactions, { loading: loadingTransactions, data: transactionData, refetch: refetchTrans }] = useLazyQuery(Graphs.GET_TRANSACTIONS);

  const [CreateRecord, createOptions] = useMutation(Graphs.CREATE_RECORDS);
  const [EditRecord, editOptions] = useMutation(Graphs.EDIT_RECORD);
  const [DeleteRecord, { loading: deleteLoading }] = useMutation(
    Graphs.DELETE_RECORD
  );
  const [DeleteTransaction, { loading: transactionDeleteLoading }] = useMutation(
    Graphs.DELETE_TRANSACTION
  );


  const { loading: createLoading } = createOptions;
  const { loading: editLoading } = editOptions;

  const [dataState, setDataState] = useState<FormDataSource[] | []>([]);
  const [exportData, setExportData] = useState(dataState);
  const [existingModal, setExistingModal] = useState(false);
  const [staleModal, setStaleModal] = useState(false);

  const [existingLog, setExistingLog] = useState<FormDataSource>();
  const [selectedCallerRecords, setSelectedCallerRecords] = useState<
    FormDataSource[]
  >();
  const [selectedCallerName, setselectedCallerName] = useState<string>();
  const [filteredChoices, setFilteredChoices] = useState<Array<string | number | boolean>>(defaultOptions)
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [sendToEmails, setSendToEmails] = useState<string[]>([]);
  const [ccEmails, setCcEmails] = useState<string[]>([]);

  useEffect(() => {
    if (selectedSupervisor) {
      setSendToEmails([selectedSupervisor.email])
    }
  }, [selectedSupervisor])



  const updateTableState = (row: FormDataSource) => {
    const newData = [...dataState];
    const index = newData.findIndex((item) => row.uniqueId === item.uniqueId);
    const item = newData[index];
    row.rank = order.indexOf(row.type);
    newData.splice(index, 1, {
      ...item,
      ...row,
    });

    setDataState(newData);
  };

  useEffect(() => {
    refetchRecords()
  }, [selectedSupervisor, refetchRecords, supervisors])

  useEffect(() => {
    refetchUser()
  }, [supervisors, selectedSupervisor, refetchUser])


  useEffect(() => {
    if (recordsData && userData) {

      const spreadData: FormDataSource[] = [...recordsData.GetRecords];
      const newDataState = spreadData.map((data: FormDataSource) => ({ ...data, rank: order.indexOf(data.type) }));
      setDataState(newDataState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordsData, userData]);

  useEffect(() => {
    if (dataState) {
      const mapRankBykey = (dataState as any).reduce((acc: any, { type }: any) => {
        const index = order.indexOf(type);
        acc[type] = index;
        return acc;
      }, {});

      const spreadData: FormDataSource[] = [...dataState];
      const newDataState = spreadData.map((data: FormDataSource) => ({ ...data, rank: mapRankBykey[data.type] }));
      setDataState(newDataState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  useEffect(() => {
    if (userData?.User?.preferences) {
      dispatch(setAllColor(userData.User.preferences.contactColorTypes));
      dispatch(setColorOrder(userData.User.preferences.contactColorTypes.order));
      dispatch(selectSupervisor(userData.User.supervisors[0]));
      dispatch(setSupervisorList(userData.User.supervisors));
    }
  }, [userData, dispatch]);

  useEffect(() => {
    if (userData?.User?.supervisors) {
      dispatch(selectSupervisor(userData.User.supervisors[0]))
      dispatch(setSupervisorList(userData.User.supervisors))
    }
  }, [dispatch, userData?.User?.supervisors])


  useEffect(() => {
    if (transactionData) {
      setSelectedCallerRecords(transactionData.GetTransactions)
    }
  }, [transactionData])

  useEffect(() => {
    const spreadData = [...dataState];
    spreadData.forEach(item => {
      item.typeDisplay = colorHelper(item.type);
      item.timestampConversion = moment
        .unix(
          item.timestamp
        )
        .format("MM/D/yy h:mm A")
      item.phoneNumberConversion = `${formatPhoneNumberType(item.phoneNumber.type)}: ${item.phoneNumber.number}`
    }
    )
    setExportData(spreadData)
  }, [dataState])

  const handleCompleted = async (record: FormDataSource) => {
    const now = moment(Date.now()).unix();

    const newCall = {
      type: record.type,
      name: record.name,
      contactId: record.contactId,
      completed: true,
      uniqueId: record.uniqueId,
      phoneNumber: record.phoneNumber,
      comment: record.comment,
      timestamp: now,
      updated: record.updated,
    };
    await EditRecord({
      variables: { record: newCall, supervisorId: selectedSupervisor?.id, newTransaction: true },
    }).then(() => {
      const newData = [...dataState];
      const index = newData.findIndex(
        (item) => record.uniqueId === item.uniqueId
      );
      newData.splice(index, 1);

      setDataState(newData);
    }).catch((error) => {
      if (error.message === StaleRecord.STALE_RECORD) {
        setStaleModal((prev) => !prev);
        return
      }
      return message.error('Error Editing Record')
    });
  };

  const onExistingOk = async () => {
    if (existingLog) {
      await handleIncrementClick(existingLog)
    }
    setExistingModal(!existingModal)
  }


  const onSubmit = async (
    newCall: FormDataSource,
    contactId: string /* Case when contact does not exist */
  ) => {
    const callExists = dataState.find((call: FormDataSource) => call.contactId === contactId);
    if (callExists) {
      setExistingModal(true);
      setExistingLog({ ...callExists, ...newCall });
    } else {
      await CreateRecord({
        variables: { record: { ...newCall, contactId }, supervisorId: selectedSupervisor?.id },
      })
        .then(({ data }) => {
          setDataState([...dataState, { ...data.CreateRecord, rank: order.indexOf(newCall.type) }]);
        })
        .catch((error) => {
          if (error.message === StaleRecord.STALE_RECORD) {
            setStaleModal((prev) => !prev);
            return
          }
          return message.error('Error Creating Record')
        });
    }
  };
  const handleDelete = async (record: FormDataSource, index: number) => {
    const { uniqueId } = record;
    await DeleteRecord({ variables: { uniqueId } }).then(() => {
      setDataState(dataState.filter((d: FormDataSource) => d.uniqueId !== uniqueId))
    }).catch(err => message.error('Error Deleting Record'));
  };

  const handleTransactionDelete = async (transactionId: string, uniqueId: string) => {
    await DeleteTransaction({ variables: { transactionId } }).then(() => {
      const stateCopy = [...dataState];
      const index = stateCopy.findIndex(data => data.uniqueId === uniqueId);
      const transactionsData = stateCopy[index].transactions.filter(data => data.transactionId !== transactionId);
      const latest = transactionsData[0];

      const newRecord = {
        ...stateCopy[index],
        phoneNumber: latest.phoneNumber,
        timestamp: latest.timestamp,
        type: latest.type,
        comment: latest.comment,
        transactions: transactionsData,
      }

      stateCopy.splice(index, 1, {
        ...newRecord
      });
      setDataState(stateCopy);
    }).catch(err => console.log('Error Deleting Transaction'));
  };

  const [sliderState, setSliderState] = useState(false);
  const handleStateSlideChange = () => {
    setSliderState(!sliderState);
  };

  const handleIncrementClick = async (record: FormDataSource) => {
    const { type, name, timestamp, contactId, uniqueId, phoneNumber, comment, updated } = record;
    const payload: any = { type, name, timestamp, contactId, uniqueId, phoneNumber, comment, updated };
    const now = moment(Date.now()).unix();

    payload.timestamp = now;
    payload.completed = false;

    // TODO: You should be using the repsonse data, not the create payload. Refactor.
    await EditRecord({
      variables: { record: payload, supervisorId: selectedSupervisor?.id, newTransaction: true },
    }).then(response => updateTableState(response.data.EditRecord)).catch((error) => {
      if (error.message === StaleRecord.STALE_RECORD) {
        setStaleModal((prev) => !prev);
        return
      }
      return message.error('Error Incrementing')
    });
  };

  const [form] = Form.useForm();

  const handleSave = async (row: FormDataSource) => {
    await EditRecord({
      variables: { record: row, supervisorId: selectedSupervisor?.id },
    }).then(response => updateTableState(response.data.EditRecord)).catch((error) => {
      if (error.message === StaleRecord.STALE_RECORD) {
        setStaleModal((prev) => !prev);
        return
      }
      return message.error('Error Saving')
    })
  };

  const handleContactSelected = (contact: string, label: string) => {
    if (refetchTrans) {
      refetchTrans()
    }
    LoadTransactions({ variables: { contactId: contact, supervisorId: selectedSupervisor?.id } })
    setselectedCallerName(label)
    handleStateSlideChange();
  };

  const handleModalOk = async () => {
    setConfirmLoading(true);

    let exportCopy = exportData
      .filter((value) => filteredChoices.includes(value.type))
      .sort(tableExportSorter);

    const html = ReactDOMServer.renderToStaticMarkup(createExportTable(exportCopy));
    await (services as any).sendEmail('/me/sendMail', `${html}`, selectedSupervisor, sendToEmails, ccEmails).then(() => {
      message.success('Email Was Sent!');
    }).catch(() => message.error('Oops something went wrong!'))
    /// logc for sending to email template
    setConfirmLoading(false)
  }

  const handleOutOfSyncRecords = async () => {
    await refetchRecords()
    setStaleModal((prev) => !prev);
  };

  const columns = [
    {
      title: "Status",
      dataIndex: "type",
      key: "type",
      defaultSortOrder: "descend",
      sortDirections: ["descend", "ascend", "descend"],
      width: "9%",
      editable: true,
      sorter: {
        compare: (a: FormDataSource, b: FormDataSource) =>
          (b.rank) - (a.rank),
      },
      render: (item: string, record: FormDataSource) => (
        <div
          style={{
            fontWeight: "bold",
            color: phoneTypeColors[record.type],
            justifyContent: 'center',
          }}
        >
          {colorHelper(record.type)}
        </div>
      ),
    },
    {
      title: "Time",
      dataIndex: "timestamp",
      key: "timestamp",
      width: "13%",
      sortDirections: ["descend", "ascend", "descend"],
      editable: true,
      sorter: {
        compare: (a: FormDataSource, b: FormDataSource) =>
          a.timestamp -
          b.timestamp,
      },
      render: (item: string, record: FormDataSource) => (
        <div>
          {moment
            .unix(
              record.timestamp
            )
            .format("MM/D/yy h:mm A")}
        </div>
      ),
    },
    {
      key: "increment",
      width: "1%",
      editable: false,
      render: (item: string, record: FormDataSource) => <Button onClick={() => handleIncrementClick(record)} shape="round" type="primary" size={'small'}> + Now </Button>
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "15%",
      render: (item: string, record: FormDataSource) => (
        <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
          {item}
          <DisplayWrapper display={record.type === PhoneType.OWE_INTERNAL}>
            <img
              src={rangeLogo}
              alt=""
              style={{ marginLeft: 10, width: 40, height: 15 }}
            />
          </DisplayWrapper>
        </div>
      ),
    },
    {
      title: "Phone",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      width: "14%",
      editable: true,
      render: (item: string, record: FormDataSource) => (
        <div style={{ display: 'flex', whiteSpace: 'nowrap' }}>
          <div style={{ marginRight: 5, color: '#595959' }}>{formatPhoneNumberType(record.phoneNumber.type)}: </div>
          {record.phoneNumber.number}</div>
      ),
    },
    {
      title: "Note",
      dataIndex: "comment",
      key: "comment",
      editable: true,
      render: (item: string) => <div style={{ padding: 10 }}>{item}</div>,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "1%",
      render: (text: string, record: FormDataSource, index: number) => {
        return (
          <>
            <Popconfirm
              title="Move to complete?"
              onConfirm={() => handleCompleted(record)}
            >
              <Button
                type="link"
                icon={<CheckCircleTwoTone twoToneColor="#52c41a" />}
              ></Button>
            </Popconfirm>
            <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={() => handleDelete(record, index)}
            >
              <Button
                type="link"
                icon={<MinusCircleTwoTone twoToneColor="#ff0000" />}
              ></Button>
            </Popconfirm>
          </>
        );
      },
    },
    {
      title: "Count",
      dataIndex: "callHistoryNumber",
      key: "callHistoryNumber",
      width: "1%",
      render: (item: string, record: FormDataSource) => (
        <div
          style={{
            fontWeight: 500,
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {record?.transactions?.length}
        </div>
      ),
    },
  ];

  const options = [
    { label: 'Left Word', value: PhoneType.LEFT_WORD },
    { label: 'Owe', value: PhoneType.OWE },
    { label: 'To Call', value: PhoneType.TO_CALL },
    { label: 'Owe Client', value: PhoneType.OWE_CLIENT },
    { label: 'Owe Internal', value: PhoneType.OWE_INTERNAL },
  ];

  const { confirm } = Modal;

  const confirmModal = () => confirm({
    title: 'Are you sure you want to send?',
    icon: <ExclamationCircleOutlined />,
    okText: 'Yes',
    cancelText: 'No',
    onOk() {
      handleModalOk()
    },
    onCancel() {
      Modal.destroyAll()
    },
  });


  const menu = (
    <Menu>
      <Menu.Item>
        <CSVLink
          filename="PhoneSheet_Log.csv"
          headers={[
            { label: "Name", key: "name" },
            { label: "Call Type", key: "typeDisplay" },
            { label: "Time", key: "timestampConversion" },
            { label: "Contact", key: "phoneNumberConversion" },
            { label: "Note", key: "comment" },
          ]}
          data={dataState.sort(tableExportSorter)}
        >
          <div>Export to CSV</div>
        </CSVLink>
      </Menu.Item>
      <Menu.Item>
        <RangeModal width={1000} title={'Send Email'} loading={confirmLoading} buttonText={'Export to Email'} onOk={confirmModal}>
          <>
            <div style={{ flex: 1, marginBottom: 20 }}>
              <div style={{ whiteSpace: 'nowrap', marginBottom: 10 }}>
                To: <EmailOptionSelect defaultEmail={selectedSupervisor?.email} setEmails={setSendToEmails} />
              </div>
              <div style={{ whiteSpace: 'nowrap' }}>
                Cc: <EmailOptionSelect setEmails={setCcEmails} />
              </div>
            </div>

            <div>
              <Checkbox.Group style={{ display: 'flex', flex: 1, marginBottom: 20, justifyContent: 'space-around', alignItems: 'center' }} options={options} defaultValue={defaultOptions} onChange={(x) => setFilteredChoices(x)} />
            </div>
            <div style={{ display: 'flex', fontWeight: 900, justifyContent: 'center' }}>Sample Preview</div>
            {createExportTable(exportData.filter((value): any => filteredChoices.includes(value.type)))}
          </>
        </RangeModal>
      </Menu.Item>
    </Menu>
  );


  const mergedColumns: any = columns.map((col) => {
    if (col.dataIndex === "name" || col.dataIndex === "action") {
      return col;
    }
    return {
      ...col,
      onCell: (record: FormDataSource) => ({
        record,
        inputtype: col.dataIndex,
        dataIndex: col.dataIndex,
        title: col.title,
        editable: col.editable,
        handleSave: handleSave,
      }),
    };
  });

  return (
    <div style={{ paddingRight: 10, paddingLeft: 10, paddingTop: 30 }}>
      { selectedSupervisor && <Typography.Title level={2}> {`${selectedSupervisor?.name}'s Phonesheet`} </Typography.Title>}
      <div className={classes.headerContainer}>
        <div style={{ flexGrow: 2 }}>
          <CallTypeahead onContactSelected={handleContactSelected} />
        </div>
        <div className={classes.buttonContainer}>
          <Dropdown overlay={menu} placement="bottomLeft">
            <Button style={{ width: 80, height: '100%', border: 0, borderRadius: 0, boxShadow: 'none' }} type="default" icon={<DownloadOutlined />}>
            </Button>
          </Dropdown>

          <CallLogForm onSubmit={onSubmit} />
        </div>
      </div>
      <Form form={form} component={false}>
        <Table
          loading={createLoading || editLoading || deleteLoading || loadingRecords || loadingUser}
          showSorterTooltip={false}
          rowKey={(record: FormDataSource) =>
            `${record.uniqueId}+${record.contactId}`
          }
          dataSource={dataState}
          columns={mergedColumns}
          components={{
            body: {
              cell: EditableCell,
              row: EditableRow,
            },
          }}
          expandable={{
            expandedRowRender: record => <ExpandedTable recordData={record.transactions} handleDelete={handleTransactionDelete} loading={transactionDeleteLoading} />
            ,
            rowExpandable: record => record?.transactions?.length > 1,
          }}
          pagination={{
            position: ["bottomCenter"],
            defaultPageSize: 50,
            showSizeChanger: true,
            pageSizeOptions: ['25', '50', '75', '100']
          }}
          footer={() => <div style={{ paddingBottom: 20 }} />}
          onChange={(pagination, filters, sorter, extra) =>
            setExportData(extra.currentDataSource)
          }
        />
      </Form>
      <Drawer
        width={'70%'}
        placement="right"
        closable={true}
        onClose={() => handleStateSlideChange()}
        visible={sliderState}
        keyboard={true}
      >
        <h3>Completed Calls For {selectedCallerName}</h3>
        <Divider></Divider>
        <ExpandedTable drawer={true} loading={loadingTransactions} recordData={selectedCallerRecords || []} />
      </Drawer>
      <Modal
        width={300}
        visible={existingModal}
        onOk={onExistingOk}
        onCancel={() => setExistingModal(!existingModal)}
      >
        <p style={{ textAlign: 'center' }}>
          <div style={{ fontWeight: 700 }}>
            {`${existingLog?.name} Already Exists.`}
          </div><br></br> {`Increment Call?`}

        </p>

      </Modal>
      <Modal
        width={300}
        visible={staleModal}
        maskClosable={false}
        closable={false}
        footer={null}
      >
        <div style={{ textAlign: 'center' }}>
          <p style={{ fontWeight: 700 }}>
            {`This record is out of date.`}
          </p>

        </div>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 15 }}>
          <Button onClick={handleOutOfSyncRecords}>Refresh</Button>
        </div>

      </Modal>
    </div >
  );
};
