export enum GetProfileInfoActionTypes {
    GET_PROFILE_INFO = '[Get Profile Info] Get Profile Info',
    GET_PROFILE_INFO_SUCCESS = '[Get Profile Info] Get Profile Info Success',
    GET_PROFILE_INFO_FAILURE = '[Get Profile Info] Get Profile Info Failure',
    SET_SUPERVISOR = '[Set Supervisor] Set Supervisor',
    SET_ALL_SUPERVISORS = '[Set Supervisors] Set All Supervisors',
    SET_USER = '[Set User] Set User',
    SET_FIRST_FLAG = '[Set User] Set First Flag',

}

export interface Supervisor {
    id: string,
    name: string,
    email: string,
}

export interface User {
    firstName: string,
    lastName: string,
    userId: string,
    supervisors: Supervisor[],
    preferences: any[],
    organizationId: string,
    isFirstTime: boolean,
}

export interface GetProfileInfo {
    type: typeof GetProfileInfoActionTypes.GET_PROFILE_INFO;
}

export interface GetProfileInfoSuccess {
    type: typeof GetProfileInfoActionTypes.GET_PROFILE_INFO_SUCCESS;
    result: any;
}


export interface GetProfileInfoFailure {
    type: typeof GetProfileInfoActionTypes.GET_PROFILE_INFO_FAILURE;
    error: Error;
}

export interface SetSupervisor {
    type: typeof GetProfileInfoActionTypes.SET_SUPERVISOR;
    supervisor: Supervisor;
}

export interface SetAllSupervisors {
    type: typeof GetProfileInfoActionTypes.SET_ALL_SUPERVISORS;
    supervisors: Supervisor[];
}

export interface SetUser {
    type: typeof GetProfileInfoActionTypes.SET_USER;
    user: User;
}

export interface SetFirstFlag {
    type: typeof GetProfileInfoActionTypes.SET_FIRST_FLAG;
    flag: boolean;
}



export type GetProfileActions =
    GetProfileInfo |
    GetProfileInfoSuccess |
    GetProfileInfoFailure |
    SetSupervisor |
    SetAllSupervisors |
    SetFirstFlag |
    SetUser;