import React, { useEffect, useState, useCallback } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Modal,
  DatePicker,
  AutoComplete,
  Divider,
  notification,
} from "antd";
import { PhoneType, FormDataSource, OutlookPhoneNumberLabel } from "../models/index";
import { getPhoneNumberType } from "../utils/table-utils";
import { PlusCircleOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import Graphs from "../graphs";
import moment from "moment";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../rootReducer";
import CreateContact, { ContactInformation } from './createContact';
import GraphService from '../utils/GraphService';

interface FormDataProps {
  onSubmit: (newCall: FormDataSource, contactId: string) => void;
}

export const CallLogForm = ({ onSubmit }: FormDataProps) => {
  const { Option } = Select;
  const [, forceUpdate] = useState();
  const [modalState, setModalState] = useState(false);

  const [newContactModalState, setNewContactModalState] = useState(false);
  const [createNewContactLoading, setCreateNewContactLoading] = useState(false);
  const [contactOptions, setContactOptions] = useState<any>();
  const [query, setQuery] = useState<string>("");
  const [contacts, setContacts] = useState<any[]>();
  const [nameSearchValue] = useState<string>("");
  const [phoneOptions, setPhoneOptions] = useState<Array<any>>([]);
  const [form] = Form.useForm();
  const [contactId, setContactId] = useState<string>("");
  const [phoneNumberType, setphoneNumberType] = useState<OutlookPhoneNumberLabel>();
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const [createEditContactInformation, setCreateEditContactInformation] = useState<any>();

  const selectedSupervisor = useSelector(
    (state: RootState) => state.PhoneSheetSceneReducer.selectedSupervisor,
    shallowEqual
  );
  const { data: SearchResults, refetch: _refetch } = useQuery(
    Graphs.SEARCH_CONTACTS,
    {
      variables: {
        query, supervisorId: selectedSupervisor?.id,
      },
    }
  );


  const refetch = useCallback(() => {
    setTimeout(() => _refetch(), 0);
  }, [_refetch]);

  useEffect(() => {
    forceUpdate(undefined);
  }, []);

  useEffect(() => {
    if (!SearchResults) return;

    const { Search: data = [] } = SearchResults;

    const options = data.map((contact: any) => ({
      key: contact.id,
      value: contact.displayName,
      label: contact.displayName,
    }));

    options.unshift({
      key: 'new_contact',
      value: 'new_contact',
      label: 'hello',
    });

    setContactOptions(options);
    setContacts(data);
  }, [SearchResults]);

  useEffect(() => {
    if (query.length > 0) {
      refetch();
    }
  }, [query, refetch]);

  const handleNameSearch = (change: string) => {
    setQuery(change);
  };

  const getContactInformation = (contact: any) => {
    const PHONE_TYPES: any = { businessPhones: 'Business Phones', homePhones: 'Home Phones', mobilePhone: 'Mobile Phone' };

    const generateOptions = (acc: any, key: any) => {
      if (PHONE_TYPES[key]) {
        const value = contact[key];
        const numbers = typeof value === 'string' ? [value] : value;

        const result = {
          label: PHONE_TYPES[key],
          options: (numbers || []).map((number: string) => ({ value: number, label: number, type: PHONE_TYPES[key] }))
        };

        acc.push(result);
      }

      return acc;
    };

    return Object.keys(contact).reduce(generateOptions, []).filter(({ options }: any) => options.length > 0);
  };

  const handleNameSearchSelect = (value: string, { key }: any) => {
    const foundContact = contacts?.find((contact) => contact.id === key);

    if (foundContact) {
      const phoneNumbers = getContactInformation(foundContact);
      const { options, label } = phoneNumbers.slice(-1)[0] || {};
      // TOOD: Push a default option for creating a contact onto outlook.
      // TODO: Handle the null case; means no contactId found.
      setphoneNumberType(label);
      options && form.setFieldsValue({
        phoneNumber: options[0].value,
        contactId: key || null,
      });

      setCreateEditContactInformation(foundContact);
      setContactId(key);
      setPhoneOptions(phoneNumbers);
    }
  };

  const onFinish = (values: any) => {
    const finishedValues = { ...values, phoneNumber: { number: values.phoneNumber, type: getPhoneNumberType(phoneNumberType) } }
    finishedValues.timestamp = moment(values.timestamp).unix();
    finishedValues.completed = false;
    onSubmit(finishedValues, contactId);
    setModalState(!modalState);
    form.resetFields();
  };

  const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 16 },
  };

  const prefixSelector = (
    <Form.Item
      label="Call Type"
      name="type"
      rules={[{ required: true, message: "Please choose a call type!" }]}
    >
      <Select style={{ width: 150 }} placeholder="Call Type">
        <Option value={PhoneType.LEFT_WORD}>Left Word</Option>
        <Option value={PhoneType.OWE}>Owe</Option>
        <Option value={PhoneType.TO_CALL}>To Call</Option>
        <Option value={PhoneType.OWE_CLIENT}>Owe Client</Option>
        <Option value={PhoneType.OWE_INTERNAL}>Owe Internal</Option>
      </Select>
    </Form.Item>
  );
  const disabled = !!form.getFieldsError().filter(({ errors }) => errors.length)
    .length;
  const handleCancel = () => {
    form.resetFields();
    setModalState(!modalState);
  };

  const onCreateContactSubmit = (contact: ContactInformation) => {
    const handleSuccess = (response: any) => {
      setphoneNumberType(OutlookPhoneNumberLabel.MOBILE_PHONE);

      form.setFieldsValue({
        phoneNumber: contact.mobilePhone,
        contactId: response.id,
        name: response.displayName,
      });

      const phoneNumbers = getContactInformation(response);
      setPhoneOptions(phoneNumbers);
      setContactId(response.id);

      setCreateNewContactLoading(false);
      setNewContactModalState(false);
      setNewContactModalState(false);


      notification['success']({
        message: `Successfully ${isEdit ? 'Edited' : 'Created New'} Contact`,
        description: `${isEdit ? 'Edited' : 'Created a new'} contact for ${response.displayName}`,
      });
    };

    const handleError = (error: any) => {
      setCreateNewContactLoading(false);
      setNewContactModalState(false);

      notification['error']({
        message: `Failed to ${isEdit ? 'edit' : 'create a new'} contact!`,
        description: error.message,
      });
    };

    const { id } = selectedSupervisor || {};
    if (id) {
      setCreateNewContactLoading(true);
      if (isEdit) {
        GraphService.patchContact(id, contactId, contact).then(handleSuccess).catch(handleError);
      } else {
        GraphService.createContact(id, contact).then(handleSuccess).catch(handleError);
      }
    }
  };

  const CreateNewContactButton = () => (
    <Button onClick={() => {
      setIsEdit(false);
      setNewContactModalState(!newContactModalState)
    }} style={{ width: '100%' }} type="primary"> + Create a new contact in Outlook </Button>
  )

  return (
    <div>
      <Button
        style={{ width: '120px', height: '100%', boxShadow: 'none', borderRadius: '0' }}
        icon={<PlusCircleOutlined />}
        type="primary"
        onClick={() => setModalState(!modalState)}
      >
        Add
      </Button>
      <Modal
        okButtonProps={{
          type: "primary",
          htmlType: "submit",
          disabled: disabled,
        }}
        onOk={() => form.submit()}
        visible={modalState}
        onCancel={() => handleCancel()}
      >
        <Modal visible={newContactModalState} onCancel={() => setNewContactModalState(false)} footer={null}
        >
          <CreateContact
            isEdit={isEdit}
            existingContact={createEditContactInformation}
            isLoading={createNewContactLoading}
            onSubmit={onCreateContactSubmit} />
        </Modal>
        <Form
          {...layout}
          form={form}
          name="horizontal_login"
          onFinish={onFinish}
        >
          <h1> Log a Call </h1>
          <Divider />
          {prefixSelector}
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: "Please input contact name!" }]}
          >
            <AutoComplete
              onSearch={handleNameSearch}
              value={nameSearchValue}
              onSelect={handleNameSearchSelect}
              placeholder="name"
            >
              {(contactOptions || []).map(({ key, value, label }: any, index: number) => (
                <Option key={key} value={value}>
                  { index ? label : <CreateNewContactButton />}
                </Option>
              ))}
            </AutoComplete>
          </Form.Item>
          <div style={{ position: 'relative' }}>
            <Form.Item
              style={{ marginRight: '5px' }}
              label="Contact"
              name="phoneNumber"
              rules={[{ required: true, message: "Please input contact number" }]}
            >
              <AutoComplete
                dropdownMatchSelectWidth={300}
                style={{ width: 250 }}
                options={phoneOptions}
                placeholder="Phone number"
                onSelect={(label, value) => setphoneNumberType(value.type)}
              />
            </Form.Item>

            {
              contactId.length > 0 &&
              <Button onClick={() => {
                setIsEdit(true);
                setNewContactModalState(!newContactModalState)
              }} style={{ position: 'absolute', top: 0, right: 45 }} type="primary" > Edit </Button>
            }
          </div>

          <Form.Item
            label="Time"
            name="timestamp"
            rules={[{ required: true, message: "Please input time" }]}
            initialValue={moment()}
          >
            <DatePicker
              showTime={{ format: "h:m A" }}
              format="MM/DD/YYYY hh:mm A"
            />
          </Form.Item>
          <Form.Item name="comment" label="Note">
            <Input.TextArea placeholder="Notes" rows={5} />
          </Form.Item>
        </Form>
      </Modal>
    </div >
  );
};
