import { formatPhoneNumber } from "./table-utils";

export const getContactInformation = (contact: any) => {
    const PHONE_TYPES: any = { businessPhones: 'Business Phones', homePhones: 'Home Phones', mobilePhone: 'Mobile Phone' };

    const generateOptions = (acc: any, key: any) => {
        if (PHONE_TYPES[key]) {
            const value = contact[key];
            const numbers = typeof value === 'string' ? [value] : value;

            const result = {
                label: PHONE_TYPES[key],
                options: (numbers || []).map((number: string) => ({ value: formatPhoneNumber(number), label: formatPhoneNumber(number), type: PHONE_TYPES[key] }))
            };

            acc.push(result);
        }

        return acc;
    };

    return Object.keys(contact).reduce(generateOptions, []).filter(({ options }: any) => options.length > 0);
};