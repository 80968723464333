const PhoneTypes = [
    {
        label: 'Business',
        value: 'business',
    },
    {
        label: 'Mobile',
        value: 'mobile',
    },
    {
        label: 'Home',
        value: 'home',
    }
];

export default PhoneTypes;